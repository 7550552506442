<div class="altoTituloPopop">
  <span class="titulo"> {{data.title }} {{modules.registroAsignacionProyecto.title}}</span>
  <span fxFlex></span>
  <button type="button" mat-icon-button class="mt--10" (click)="dialogRef.close()">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography dialog-content-200" style="text-align: center" >

<br><br><br>
      <p ><span>
        Limite de Horas registro sobre pasadas <br>
        Reporte enviado al <strong>Administrador</strong>
      </span><br>
      <strong>maximo 8 horas</strong>
      </p>  
      Aceptar y continuar 

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="mr-1" type="button" size="small" matRipple nbButton status="disabled"
    (click)="dialogRef.close(false)">{{Botton_action.Cancelar.nombre}}</button>

  <button nbButton matRipple size="small" (click)="continuar()" status="primary">{{Botton_action.Aceptar.nombre}}</button>

</mat-dialog-actions>