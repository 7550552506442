import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LOCALE_ID, NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { CoreModule } from "./@core/core.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ThemeModule } from "./@theme/theme.module";
import { AuthModule } from "./@auth/auth.module";
import { ActionReducerMap, MetaReducer, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { NbMenuModule, NbSidebarModule, NbToastrModule } from "@nebular/theme";
import { environment } from "environments/environment";
import { EffectsModule } from "@ngrx/effects";
import { StoreRouterConnectingModule } from "@ngrx/router-store";
import { storeFreeze } from "ngrx-store-freeze";
import { NgrxRouterStoreModule } from "./ngrx/ngrx-router.module";
import { appReducer } from "./ngrx/app.reducer";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { dateFormatPerzonalized } from "./@components/confi/confi";
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { CostumPaginator } from "app/@components/services/costumPaginator";
import { NgProgressModule } from "@ngx-progressbar/core";
import { ErpModule } from "./view/erp/erp.module";
registerLocaleData(localeEs, "es");
export const metaReducers: MetaReducer<{}>[] = !environment.production
  ? [storeFreeze]
  : [];

const NGRX_IMPORTS = [
  StoreModule.forRoot(appReducer as ActionReducerMap<{}>, { metaReducers }),
  EffectsModule.forRoot([]),
  !environment.production ? StoreDevtoolsModule.instrument() : [],
  NgrxRouterStoreModule,
];
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbToastrModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NgrxRouterStoreModule,
    StoreRouterConnectingModule.forRoot(),
    NgProgressModule,
    ...NGRX_IMPORTS,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "es-ES" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: dateFormatPerzonalized },
    { provide: LOCALE_ID, useValue: "es" },
    { provide: MatPaginatorIntl, useValue: CostumPaginator() },
  ],
})
export class AppModule {}
