
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import *  as indexActions from 'app/@components/general/ngrx/index/index.actions'
import { LayoutService } from 'app/@core/utils';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  public readonly materialTheme$: Observable<boolean>;
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  //datosEmpresaSub: Subscription = new Subscription();
 // items: any[] = []
  currentTheme = 'material-dark';
  userMenu: any={};
  userSub: Subscription = new Subscription();
  //variables
  usernName: string = '';
  secondName: string = '';
  usernName1: string = '';
 // selected:any;
  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private store: Store<any>,
  ) {
    this.materialTheme$ = this.themeService.onThemeChange()
      .pipe(
        map(theme => {
          const themeName: string = (theme && theme.name) || '';
          return themeName.startsWith('material');
        }));
  }
  ngOnInit() {
    this.userMenu = this.getMenuItems();
    this.userSub = this.store.select((state) => state?.auth?.nombreUsuario)
      .subscribe(data => {
        if (data) {
          this.usernName = data;
        }
      });
    this.userSub = this.store.select((state) => state?.auth?.apellidoUsuario)
      .subscribe(data => {
        if (data) {
          this.secondName = data
          this.usernName = this.usernName + " " + this.secondName
          this.usernName1 = this.usernName.substring(0, 1) + " " + this.secondName.substring(0, 1)
          this.userMenu = this.getMenuItems();
        }
      });
    this.currentTheme = this.themeService.currentTheme;
    /*this.userSub = this.store.select((state) => state?.auth?.clienteId)
    .subscribe(data => {
      if (data) {
        this.store.dispatch(indexActions.GetByIdListEmpresaAction({ idCliente: data, controller: "empresa", actionController: "GetByIdList" }));
      }
    });*/
    /*this.datosEmpresaSub = this.store.select((state) => state?.general?.index?.getByIdListEmpresa)
      .subscribe(items => {
        if (items) { 
          if(items?.length==1)
          {
         this.selected=  items[0]["fS07Id"] 
          }
          this.items = items }
      });*/
   

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);
    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        this.currentTheme = themeName;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
   // this.datosEmpresaSub.unsubscribe();
    this.userSub.unsubscribe()
  }
  getMenuItems() {
    return [
      { title: this.usernName },
      { title: 'cerrar sesión', "link": '/auth/logout' },
    ];
  }
  
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
