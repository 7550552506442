
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NB_AUTH_OPTIONS } from '@nebular/auth';
import { AuthService } from 'app/@auth/auth.service';
import { AS02Usuario } from 'app/@components/models/AS02Usuario';

import { getDeepFromObject } from '../../helpers';

@Component({
  selector: 'ngx-reset-password-page',
  styleUrls: ['./reset-password.component.scss'],
  templateUrl: './reset-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxResetPasswordComponent implements OnInit {
  minLength: number = this.getConfigValue('forms.validation.password.minLength');
  maxLength: number = this.getConfigValue('forms.validation.password.maxLength');
  redirectDelay: number = this.getConfigValue('forms.resetPassword.redirectDelay');
  showMessages: any = this.getConfigValue('forms.resetPassword.showMessages');
  strategy: string = this.getConfigValue('forms.resetPassword.strategy');
  isPasswordRequired: boolean = this.getConfigValue('forms.validation.password.required');
  submitted = false;
  resetPasswordForm: FormGroup;
  loading = false;
  errors= false;
  success= false;
  correo:string;
  constructor(
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected fb: FormBuilder,
    protected router: Router,
    protected service: AuthService,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.correo= this.activatedRoute.snapshot.paramMap.get('id');
    const passwordValidators = [
      Validators.minLength(this.minLength),
      Validators.maxLength(this.maxLength),
    ];
    this.isPasswordRequired && passwordValidators.push(Validators.required);
    this.resetPasswordForm = this.fb.group({
      password: this.fb.control('', [...passwordValidators]),
      confirmPassword: this.fb.control('', [...passwordValidators]),
    });
  }

  get password() { return this.resetPasswordForm.get('password'); }
  get confirmPassword() { return this.resetPasswordForm.get('confirmPassword'); }

  requestPass(): void
  {
    
    let usuario: AS02Usuario;

    usuario={aS02Correo:this.correo, aS02Contrasena:this.resetPasswordForm.value.password 
    } 
   this.submitted = true;
   this.loading = true;
   this.service.ChangePassword(usuario).subscribe(data => {
     
     this.submitted = false;
     this.loading = false;
     if (data.success)
      {
       this.errors = false;
       this.success=true;
       this.resetPasswordForm.reset()
     } 
     else {
       this.errors = true;
       this.success=false;
     }
     this.cd.detectChanges();
   },(err)=>{
     
     this.submitted = false;
     this.loading = false;
     this.errors = true;
     this.success=false;
     this.cd.detectChanges();
   });
 }
  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  
}
