
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Botton_action, titulos } from 'app/@components/confi/confi';
import *as generalActions from '../../ngrx/general.actions'
import * as uuid from 'uuid';
import { Subscription } from 'rxjs';
import * as indexActions from 'app/@components/general/ngrx/index/index.actions'
@Component({
  selector: 'ngx-crud-anular-popup',
  templateUrl: 'crud-anular-popup.component.html'
})
export class CrudAnularPopupComponent implements OnInit, OnDestroy {
  public itemForm: FormGroup;
  Botton_action = Botton_action
  titulos = titulos
  popupId = uuid.v4();
  uuidResponseSub: Subscription = new Subscription();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CrudAnularPopupComponent>,
    private store: Store<any>,
  ) { }
  ngOnInit() 
  {
    //close de popup
    this.uuidResponseSub = this.store.select((state) => state?.general?.item[this.popupId]?.uuid)
      .subscribe(uuid => {
        if (uuid) {
          this.close();
        }
      });  
  }
  ngOnDestroy() {
    this.uuidResponseSub.unsubscribe();
   // this.store.dispatch(indexActions.ClearStorageAction({ popupId: this.popupId }));
  }
  submit() 
  {
    if(this.data?.tipoAnulacion)
    {
      this.store.dispatch(generalActions.AnularItemsAction({ module:this.data.module, actionResult: this.data.actionResult, ids:this.data.data, popupId: this.popupId, reloadIndex:true, tipoMensaje:"4" }));
    }
    {
      this.dialogRef.close(this.data.data);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
