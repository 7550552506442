
<h1 id="title" class="title">Cambia la contraseña</h1>
<p class="sub-title">Establezca una nueva contraseña</p>

<nb-alert *ngIf="errors && !success && !submitted" outline="danger" role="alert">
  <p class="alert-title"><b>Error!</b></p>
  <ul class="alert-message-list">
    <li  class="alert-message">No existe el correo electrónico </li>
  </ul>
</nb-alert>

<nb-alert *ngIf="!errors && success  && !submitted" outline="success" role="alert">
  <p class="alert-title"><b>Exito!</b></p>
  <ul class="alert-message-list">
    <li  class="alert-message">Se ha enviado los accesos al correo registrado</li>
  </ul>
</nb-alert>


<form [formGroup]="resetPasswordForm" aria-labelledby="title">

  <div class="form-control-group">
    <label for="input-password">Contraseña:</label>
    <input  nbInput
            autofocus
            fullWidth
            id="input-password"
            formControlName="password"
            type="password"
            [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
            class="first"
            placeholder="Contraseña">
    <ngx-validation-message label="Contraseña" [showMinLength]="password?.hasError('minlength') && password.touched"
      [showMaxLength]="password?.hasError('maxlength') && password.touched"
      [showRequired]="password?.errors?.required && password.touched"
      [minLength]="minLength"
      [maxLength]="maxLength"></ngx-validation-message>
  </div>

  <div class="form-control-group">
    <label for="input-re-password">Confirmar contraseña:</label>
    <input  nbInput
            fullWidth
            id="input-re-password"
            formControlName="confirmPassword"
            type="password"
            [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : ''"
            placeholder="Confirmar contraseña">
    <ngx-validation-message label="Confirmar contraseña"
      [showMinLength]="confirmPassword?.hasError('minlength') && confirmPassword.touched"
      [showMaxLength]="confirmPassword?.hasError('maxlength') && confirmPassword.touched"
      [showRequired]="confirmPassword?.errors?.required && confirmPassword.touched"
      [minLength]="minLength"
      [maxLength]="maxLength"></ngx-validation-message>
    <p  class="error-message caption status-danger" *ngIf="password.value != confirmPassword.value && confirmPassword.touched">
      ¡La contraseña y la contraseña de confirmación no coinciden!
    </p>
  </div>

  <button nbButton status="success" fullWidth [disabled]="submitted || !resetPasswordForm.valid || password.value != confirmPassword.value"
    [class.btn-pulse]="submitted"
    (click)="requestPass()">
    Cambiar la contraseña
  </button>
</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
  <p><a class="text-link" routerLink="../login">Volver a iniciar sesión</a></p>
  
</section>
