

 <mat-form-field class="input-example">
  <mat-label>{{buscar_por_columna.buscar}}</mat-label>
  <input matInput type="text" [formControl]="search"  autocomplete="off" >
</mat-form-field>

  


      
