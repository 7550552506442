
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import { HttpService } from '../services/http.service';
import * as generalActions from './general.actions'
import {ShowToastService} from '../../showToast.service'
import { Store } from '@ngrx/store';
import * as uuid from 'uuid';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import * as indexActions from './index/index.actions';
@Injectable()
export class GeneralEffects {

  progressRef: NgProgressRef;
  constructor(
    private actions$: Actions,
    private showToastService:ShowToastService,
    private httpService: HttpService,
    private store: Store<any>,
    private ngProgress: NgProgress
  ) {
    this.progressRef = this.ngProgress.ref();
  }
  SetErrorAction$ = createEffect(() =>
  this.actions$.pipe(
    ofType(generalActions.SetErrorAction),
    switchMap((error) =>
    this.showToastService.error(error).pipe(map(error => {
      let mensa:boolean=false;
      if(error=="555")
      {
        mensa=true
      }
      if(error=="444")
      {
        mensa=true
      }
      let enableDesable={enableDesable:false, mensajePersonalizado: mensa }
        return generalActions.SetEnableDesableButton({ enableDesable:enableDesable});

    
    }))
  
)));
//sin menu para el usuario
/*SetErrorWithOutAction$ = createEffect(() =>
  this.actions$.pipe(
    ofType(generalActions.SetErrorWithOutAction),
    switchMap((error) =>
    this.showErrorWithoutmenuService.showWithOutMenu(error).pipe(map(() => generalActions.UnSetErrorWithOutAction()))
)));*/
//valida identificacion por cliente
GetExistsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generalActions.GetExistsAction),
      switchMap((action) =>
        this.httpService.getExists(action.existsIdentification,action.module, action.funtion).pipe(
          map((res: any) => generalActions.SetExistsAction({ existsIdentification: res.data.success}))
        ))));

        DeleteItemsAction$ = createEffect(() =>
        this.actions$.pipe(
          ofType(generalActions.DeleteItemsAction),
          switchMap((action) =>
            this.httpService.deleteItems(action.module, action.actionResult, action.ids).pipe(
              map((res: any) => {
                
                if(action.reloadIndex)//si va ceerrar el popup depsues de eliminar la informacion
                {
                  generalActions.SetDeleteDataPopupItemsAction({ uuid: uuid.v4()});
                } 
                return generalActions.PostPutDeleteSuccessAction({ uuid: uuid.v4(), popupId: action.popupId, reloadIndex:action.reloadIndex,  tipoMensaje:"3", cerrarPupop:action.cerrarPupop });
              })
            ))));
//anular datos
AnularItemsAction$ = createEffect(() =>
            this.actions$.pipe(
              ofType(generalActions.AnularItemsAction),
              switchMap((action) =>
                this.httpService.anularItems(action.module, action.actionResult, action.ids).pipe(
                  map((res: any) => {
                    //generalActions.SetDeleteDataPopupItemsAction({ uuid: uuid.v4()});
                   // generalActions.SetAnularDataPopupItemsAction({reiniciar:true})
                    return generalActions.PostPutDeleteSuccessAction({ uuid: uuid.v4(), popupId: action.popupId, reloadIndex:action.reloadIndex, tipoMensaje:"4" });
                  })
                ))));

            EnableLoadAction$ = createEffect(() =>
            this.actions$.pipe(
              ofType(generalActions.EnableLoadAction),
              tap(() => {
                
                this.progressRef.start();
                this.progressRef.set(0);
              })
            ), { dispatch: false });
        
          DisableLoadAction$ = createEffect(() =>
            this.actions$.pipe(
              ofType(generalActions.DisableLoadAction),
              tap(() => {
                this.progressRef.complete();
              })
            ), { dispatch: false });
        
            GetDataFormAction$ = createEffect(() =>
            this.actions$.pipe(
              ofType(generalActions.GetDataFormAction),
              switchMap((action) =>
                this.httpService.GetDataForm(action.id, action.id1,  action.module, action.actionResult, action.parameters).pipe(
                  map((res: any) => {
                    return generalActions.SetDataFormAction({ dataForm: res.data, popupId: action.popupId });
                  })
          ))));

          GetObjectAction$ = createEffect(() =>
          this.actions$.pipe(
            ofType(generalActions.GetObjectAction),
            switchMap((action) =>
              this.httpService.GetByIdList(action.id, action.module, action.actionResult).pipe(
                map((res: any) => generalActions.SetObjectAction({ object: res.data, popupId: action.popupId }))
        ))));

}


