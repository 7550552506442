import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment'
import { AS02Usuario } from '../@components/models/AS02Usuario';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthService 
{
  get apiUrl(): string {
    return environment.apiUrlLogin;
  }
 constructor(private http:HttpClient)
  {
  
  }
  login(correo: string, password: string):Observable<AS02Usuario>
   {
     
    return this.http.post<AS02Usuario>(`${this.apiUrl}/Auth/Login`,{AS02Correo:correo, AS02Contrasena:password});
  }

  access(correo: string, password: string):Observable<AS02Usuario>
   {
     
    return this.http.post<AS02Usuario>(`${this.apiUrl}/Auth/Token`,{AS02Correo:correo, AS02Contrasena:password});
  }

  ResetPassword(correo: string):Observable<AS02Usuario>
   {
    
    return this.http.post<AS02Usuario>(`${this.apiUrl}/Auth/RequestPassword`,{AS02Correo:correo});
  }

  ChangePassword(usuario: AS02Usuario):Observable<AS02Usuario>
  {
   
   return this.http.post<AS02Usuario>(`${this.apiUrl}/Auth/RequestPassword`,usuario);
 }
 
}



