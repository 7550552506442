import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as generalActions from "app/@components/general/ngrx/general.actions";
import { Empresa } from "app/@components/models/Empresa";
import * as uuid from "uuid";
import { Botton_action, modules } from "app/@components/confi/confi";
import * as indexActions from "app/@components/general/ngrx/index/index.actions";

@Component({
  selector: "ngx-crud-empresa",
  templateUrl: "./crud-empresa.component.html",
  styleUrls: ["./crud-empresa.component.scss"],
})
export class CrudEmpresaComponent implements OnInit, OnDestroy {
  empresas: Empresa[] = [];
  dataFormSub: Subscription = new Subscription();
  popupId = uuid.v4();
  Botton_action = Botton_action;
  modules = modules;
  constructor(private store: Store<any>) {}
  ngOnInit(): void {
    //obtienes los datos para realizar la busqueda
    this.store.dispatch(
      generalActions.GetDataFormAction({
        id: undefined,
        module: this.modules.empresa.name,
        popupId: this.popupId,
        actionResult: "GetData",
      })
    );
    this.dataFormSub = this.store
      .select((state) => state?.general?.item[this.popupId]?.dataForm)
      .subscribe((dataForm) => {
        if (dataForm) {
          this.empresas = dataForm?.empresas;
        }
      });
  }
  ngOnDestroy(): void {
    this.dataFormSub.unsubscribe();
    this.store.dispatch(generalActions.ClearStorageAction());
  }
  search(item: any) {
    this.store.dispatch(indexActions.SetIdEmpresaAction({ itemEmpresa: item }));
  }
}
