import * as authActions from './auth.actions';
import { createReducer, on, Action } from '@ngrx/store';

export interface State {
  menus: any[];
  lecturaMenus: any[];
  clienteId:number
  nombreUsuario:string;
  apellidoUsuario:string
  aP01Cod:string
  primarysid:number
}


export const initialState: State = {
  menus: undefined,
  lecturaMenus: undefined,
  clienteId: undefined,
  nombreUsuario:undefined,
  apellidoUsuario:undefined,
  aP01Cod: undefined,
  primarysid:undefined

};

const authReducer = createReducer(
    initialState,
    on(authActions.ClearStorageAction, (state) => ({ ...initialState })),
    on(authActions.GetAuthAction, (state, { auth }) => ({ ...state })),
    on(authActions.SetAuthAction, (state, { auth }) =>
     ({ ...state,  
      menus: auth.menus,
      lecturaMenus:auth.lecturaMenus,
      clienteId:auth.aS01Id, nombreUsuario:auth.aS02Nombre, 
      apellidoUsuario:auth.aS02Apellido, aP01Cod:auth.aP01Cod, 
      primarysid:auth.aS02Id
    })), 
    on(authActions.SetAuthLocalStorageAction, (state, { auth }) =>
     ({ ...state,  
      
      menus: auth.menus,
      lecturaMenus:auth.lecturaMenus,
      clienteId:auth.aS01Id, nombreUsuario:auth.aS02Nombre, 
      apellidoUsuario:auth.aS02Apellido, aP01Cod:auth.aP01Cod, 
      primarysid:auth.aS02Id
    })), 

    );

export function reducer(state: State | undefined, action: Action) {
    return authReducer(state, action);
}

export const authFeatureKey = 'auth';
