<div class="container-fluid crud-button">
  <div class="row crud-search">
    <ngx-crud-title></ngx-crud-title>
    <span fxFlex></span>
    <ngx-crud-buttom-add></ngx-crud-buttom-add>
  </div>
  <div class="row search">
    <div class="col-12 col-md-6 pr-md-6">
      <mat-form-field class="input-example">
        <mat-label class="label">Seleccionar Empresa </mat-label>
        <mat-select (selectionChange)="search($event.value)">
          <mat-option *ngFor="let item of empresas" [value]="item">{{
            item?.fS07NombreComercial
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 col-md-6 pr-md-6"></div>
  </div>
</div>
<ng-content></ng-content>
