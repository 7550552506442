import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
@Component({
  selector: 'ngx-crud',
  templateUrl: './crud.component.html',
  styleUrls: ['./crud.component.scss']
})
export class CrudComponent implements OnInit, OnDestroy {
  loading = false;
  loadingSub: Subscription = new Subscription();
  constructor(    private store: Store<any>,
    private cd: ChangeDetectorRef,) { }
 
  ngOnInit(): void {
    /*this.loadingSub = this.store.select(state => state.general.loading)
    .subscribe(loading => {
     
      if (loading != null && loading != undefined) 
      {
        this.loading = loading;
        this.cd.detectChanges();
      }
    });*/
  }
  ngOnDestroy(): void {
    this.loadingSub.unsubscribe()
  }
}
