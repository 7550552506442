import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopupComponent } from './general/error-pupup/error-pupup.component'
@Injectable({
  providedIn: 'root'
})
export class showErrorService {

  constructor(
    private dialog: MatDialog) { }

  showError(error) {

    let errorMessage = '';

    if (error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `<strong>Client-side error: ${error?.error?.message}</strong>`;
    } else {
      // backend error
      errorMessage = `<strong>Server-side error: ${error?.status} ${error?.message}</strong>`;
    }

    let listMessages = '';

    if (error?.error && error?.error?.errors && error?.error?.errors?.length > 0) {
      let messages = '';

      error?.error?.errors.forEach(item => {
        if (item.msg && item.param) {
          messages += `<li><strong>${item.param}</strong> ${item.msg} </li>`;
        }
      });

      if (messages.length > 1) {
        listMessages = `<br />
                        <div class="card card-body" style="background-color:transparent; padding:5px; margin:10px 0; border-color: #f1b0b7;">
                          <ul style="margin-bottom: 0;">
                            ${messages}
                          </ul>
                        </div>`;
      }
    }

    if (error.error && error.error.message) {
      errorMessage = `
                      ${errorMessage}
                      <hr />
                      <div>
                        ${error.error?.message}
                        ${listMessages}
                      </div>`;
    }



    if (error.error && error.error?.errmsg) {
      errorMessage = `
                      ${errorMessage}
                      <hr />
                      <div>
                        ${error.error?.errmsg}
                        ${listMessages}
                      </div>`;
    }

    errorMessage = `
                      ${errorMessage}
                      <hr />
                      <pre>
                      ${JSON.stringify(error, undefined, 4)}
                      </pre>`;
    this.dialog.open(ErrorPopupComponent, {
      autoFocus: true,
      closeOnNavigation: false,
      position: { top: '30px' },
      width: '800px',
      data: {
        title: `Error ${error.status || ''}`,
        message: errorMessage,
        mainMessage: error.error?.message,
        listaError: ((error && error?.error && error?.message && error?.message.length > 0) ? error?.message : null)
      }
    });

    return of();
  }

}
