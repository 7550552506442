
<h1 id="title" class="title" >Login</h1>
<p class="sub-title">Iniciar sesión con su cuenta</p>
<nb-alert *ngIf="errors && !success && !submitted && open" outline="danger" role="alert" closable (close)="open=false">
  <p class="alert-title"><b>Usuario incorrecto!</b></p>
  <ul class="alert-message-list">
    <li  class="alert-message">La combinación de inicio de sesión / correo electrónico o contraseña no es correcto, inténtelo de nuevo.</li>
  </ul>
</nb-alert>
<nb-alert *ngIf="!errors && success  && !submitted && open" outline="success" role="alert">
  <p class="alert-title"><b>Exito!</b></p>
 
  <ul class="alert-message-list">
    <li  *ngIf="!sinAplicacion" class="alert-message">Accesos ingresados correctamente </li>
    <li *ngIf="sinAplicacion" class="alert-message"> <span >
      No ha sido asignado una aplicación 
    </span></li>
  </ul>
</nb-alert>

<form [formGroup]="loginForm" autocomplete="off" aria-labelledby="title" [nbSpinner]="loading" nbSpinnerStatus="success" nbSpinnerSize="giant">
  <div class="form-control-group">
    <label for="input-email">Correo electrónico:</label>
    <input  nbInput
            fullWidth
            id="input-email"
            formControlName="email"
            [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
            [attr.aria-invalid]="email.invalid && email.touched ? true : null"
            placeholder="correo electrónico">
    <ngx-validation-message label="Correo electrónico" [showPattern]="email?.hasError('pattern') && email.touched"
      [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
  </div>


  <div class="form-control-group">
    <span class="label-with-link">
      <label class="input-email" for="input-password">Contraseña:</label>
      <a class="forgot-password caption-2" routerLink="../request-password">Olvido Contraseña?</a>
    </span>
    <div class="password-input-container">
      <input nbInput
             fullWidth
             id="input-password"
             formControlName="password"
             type="{{ showPassword ? 'text' : 'password' }}"
             [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''"
             placeholder="Contraseña"
             (keydown.enter)="login()">
      <button nbButton status="basic" size="medium" class="toggle-password-button" (click)="togglePasswordVisibility()">
        <i class="toggle-password-icon" [ngClass]="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
      </button>
    </div>
    <ngx-validation-message label="Contraseña"  
      [showRequired]="password?.errors?.required && password.touched">
    </ngx-validation-message>
  </div>
  
  
<!--
  <div class="form-control-group accept-group">
    <mat-checkbox formControlName="rememberMe" name="rememberMe" *ngIf="rememberMe">Remember me</mat-checkbox>
  </div>
-->
  <button nbButton fullWidth status="primary" size="large"
   (click)="login()" [disabled]="submitted || !loginForm.valid"
    [class.btn-pulse]="submitted">
    Iniciar sesión
  </button>
</form>





<!--

<section class="another-action" aria-label="Register">
  Don't have an account? <a class="text-link" routerLink="../register">Register</a>
</section>
-->
<style>
.password-input-container {
  position: relative;
}

.toggle-password-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  color: #007bff; /* Color del texto */
  cursor: pointer;
}

.toggle-password-button:hover {
  color: #0056b3; /* Cambio de color al pasar el ratón */
}

.toggle-password-button:focus {
  outline: none; /* Elimina el borde de enfoque */
}
.toggle-password-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.toggle-password-icon {
  font-size: 1.2em; /* Tamaño del icono */
}

.toggle-password-button:hover .toggle-password-icon {
  color: #007bff; /* Cambio de color al pasar el ratón */
}

.toggle-password-button:focus {
  outline: none; /* Elimina el borde de enfoque */
}

</style>