import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Botton_action} from 'app/@components/confi/confi';
import {  FormGroup } from '@angular/forms';
import { ClienteAppDetalle } from 'app/@components/models/ClienteAppDetalle';
@Component({
  selector: 'app-empresaLink-popup',
  templateUrl: './empresaLink-popup.component.html',
  styleUrls: ['./empresaLink-popup.component.scss']
})


export class EmpresaLinkPopupComponent implements OnInit {

  public itemForm: FormGroup;
  Botton_action=Botton_action
  detalle:ClienteAppDetalle[]=[]
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EmpresaLinkPopupComponent>,
  ) { }

  ngOnInit()
   {
     this.detalle=this.data.payload
   
  }

 
  submit(link?:string) 
  {
   
    this.dialogRef.close( link)

  }
}
