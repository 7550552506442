import { createAction, props } from "@ngrx/store";
export const ClearStorageAction = createAction(
  "[INDEX] CLEAR STORAGE",
  props<{ popupId: string }>()
);
export const GetItemsAction = createAction("[INDEX] GET ITEMS");
export const SetItemsAction = createAction(
  "[INDEX] SET ITEMS",
  props<{ items: any[]; count: number }>()
);

export const GetAllAction = createAction(
  "[INDEX] GET ALL",
  props<{ quantity: number }>()
);

export const SetAllAction = createAction(
  "[INDEX] SET ALL",
  props<{ items: any[] }>()
);
export const PostAction = createAction(
  "[INDEX] SET POST ITEM",
  props<{
    data: any;
    module: string;
    popupId: string;
    formData?: boolean;
    showNotification?: boolean;
    noReloadIndex?: boolean;
    cerrarPupop?: boolean;
  }>()
);

export const PutAction = createAction(
  "[INDEX] SET PUT ITEM",
  props<{
    id: string;
    data: any;
    module: string;
    popupId: string;
    formData?: boolean;
    noReloadIndex?: boolean;
    noShowToastr?: boolean;
    cerrarPupop?: boolean;
  }>()
);

export const GetByIdAction = createAction(
  "[INDEX] GET GetPutByIdAction",
  props<{
    id: number;
    id1?: number;
    module: string;
    actionResult?: string;
    popupId?: string;
  }>()
);

export const SetByIdAction = createAction(
  "[INDEX] SET GetPutByIdAction",
  props<{ object: any; popupId: string }>()
);

export const GetByIdListAction = createAction(
  "[INDEX] GET BYIDLIST",
  props<{
    id: any;
    id1?: any;
    module: string;
    actionResult: string;
    popupId: string;
  }>()
);

export const SetByIdListAction = createAction(
  "[INDEX] SET BYIDLIST",
  props<{ items: any; popupId: string }>()
);

export const GetListById = createAction(
  "[INDEX] GET ListById",
  props<{ id: any; module: string; actionResult: string; popupId: string }>()
);

export const SetListById = createAction(
  "[INDEX] SET ListById",
  props<{ items: any[]; popupId: string }>()
);

export const NumberPageQuantityAction = createAction(
  "[INDEX] Number pag",
  props<{ page: number; quantity: number }>()
);

export const ChangeOrderByAndOrderTypeAction = createAction(
  "[INDEX] ORDERBY ASC DESC  AND ORDERTYPE",
  props<{ orderBy: string; orderType: string }>()
);

//busca datos por tipo
export const SearchAction = createAction(
  "[INDEX]  SEARCH ",
  props<{ search: string }>()
);
//item empresa, seleciona desde la plantilla general
export const SetIdEmpresaAction = createAction(
  "[INDEX] GET DATA EMPRESA",
  props<{ itemEmpresa: any }>()
);

//seleeciona desde un popup
export const SetItemEmpresaAction = createAction(
  "[INDEX] GET DATA EMPRESA",
  props<{ itemEmpresaPupop: any }>()
);

//envia parametros para el filtro en los reportes
export const GetParametersReportschAction = createAction(
  "[INDEX]  GET PARAMETERS REPORTS ",
  props<{ id?: any; id1?: any; parameters?: any }>()
);
export const GetParametersId = createAction(
  "[INDEX]  GET PARAMETERS REPORTS ",
  props<{ id?: any }>()
);

//paginamos los objecto geberal
export const GetObjectActionPagination = createAction(
  "[ITEM] GET OBJECT",
  props<{
    id?: any;
    module?: string;
    popupId?: string;
    actionResult?: string;
    quantity: number;
    page: number;
    searchText?: string;
  }>()
);

export const SetObjectActionPagination = createAction(
  "[ITEM] SET OBJECT",
  props<{ listCiiu: any[]; popupId: string; searcText: string }>()
);
//paginamos los Ciuu
export const GetCiiuPaginationAction = createAction(
  "[ITEM] GET OBJECT",
  props<{
    id?: any;
    module?: string;
    popupId?: string;
    actionResult?: string;
    quantity: number;
    page: number;
    searchText?: string;
  }>()
);

export const SetCiiuPaginationAction = createAction(
  "[ITEM] SET OBJECT",
  props<{ listCiiu: any[]; popupId: string; searcText: string }>()
);

//paginamos los proyectos
export const GetProyectoPaginationAction = createAction(
  "[ITEM] GET OBJECT PAGINATION",
  props<{
    id?: any;
    module?: string;
    popupId?: string;
    actionResult?: string;
    quantity: number;
    page: number;
    searchText?: string;
  }>()
);

export const SetProyectoPaginationAction = createAction(
  "[ITEM] SET OBJECT PAGINATION",
  props<{ proyecto: any[]; popupId: string; searcText: string }>()
);

//datos de localizacion
export const GetProvinciasByIdPaisAction = createAction(
  "[ITEM] GET PROVINCIAS BY PAIS",
  props<{
    id: any;
    id1?: any;
    popupId: string;
    module?: string;
    actionResult?: string;
  }>()
);
export const SetProvinciasByIdPaisAction = createAction(
  "[ITEM] SET PROVINCIAS BY PAIS",
  props<{ provincias: any[]; popupId: string }>()
);
export const GetCiudadesByIdProvinciaAction = createAction(
  "[ITEM] GET CIUDADES BY PROVINCIA",
  props<{
    id: any;
    id1?: any;
    popupId: string;
    module?: string;
    actionResult?: string;
  }>()
);
export const SetCiudadesByIdProvinciaAction = createAction(
  "[ITEM] SET CIUDADES BY PROVINCIA",
  props<{ ciudades: any[]; popupId: string }>()
);

export const GetpreguntaAction = createAction(
  "[ITEM] GET PREGUNTA",
  props<{
    id: any;
    id1?: any;
    popupId: string;
    module?: string;
    actionResult?: string;
  }>()
);

export const SetpreguntaAction = createAction(
  "[ITEM] SET PREGUNTA",
  props<{ preguntas: any[]; popupId: string }>()
);
