

<div class="altoTituloPopop">
  <span class="titulo"> {{data?.title }}  </span>
  <span fxFlex></span>
  <button type="button" mat-icon-button class="mt--10" (click)="close()">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <nb-alert outline="warning">
          <span>{{data?.anular?titulos.confirmarAnulacion:titulos.confirmarDesanulacion}} </span>
          <div *ngFor="let item of data?.data">
            <b> {{item?.nombre}}</b>
          </div>
        </nb-alert>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="mr-1" type="button" matRipple nbButton status="disabled"
    (click)="close()">{{Botton_action.Cancelar.nombre}}</button>
  <button nbButton matRipple status="primary" (click)="submit()">{{data?.anular?Botton_action.Anular.nombre:Botton_action.Desanulacion.nombre}}</button>
</mat-dialog-actions>



