import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { CacheService } from './cache.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as authActions from './ngrx/auth.actions';
import { Store } from '@ngrx/store';
import { AS02Usuario } from 'app/@components/models/AS02Usuario';
@Injectable({
  providedIn: 'root'
})
export class AuthTokenService extends CacheService {
  auth: any;
  get apiUrlLoginRegresa(): string {
    return environment.apiUrlLoginRegresa;
  }
  constructor(private store: Store<any>,) {
    super();
    this.ValidateLocalStore() ;
     this.store.select('auth').subscribe(state => this.auth = state);
    
  }
 
  SaveUserLocalStore(data: any) 
  {
    const helper = new JwtHelperService();
    this.setToken(data.token);
    this.setDataReading(data)
  }
  ValidateLocalStore() 
  {   
    if (this.getToken())
     {
       let data=JSON.parse(localStorage.getItem('dataReading'));
       
      this.store.dispatch(authActions.SetAuthAction({ auth: data}));
     /* const helper = new JwtHelperService();
      const user = helper.decodeToken(this.getToken());
      let dataUser:AS02Usuario={aS02Id:user.primarysid, 
                              aP01Cod:user.aP01Cod, 
                               aS01Id:user.clienteId,
                               aS02Nombre:user.nombreUsuario,
                               aS02Apellido:user.apellidoUsuario
                              };
      this.store.dispatch(authActions.GetAuthAction({auth:dataUser}));*/
    
     }
  }
  logout() {
    authActions.ClearStorageAction();
    localStorage.removeItem('jwt');
    localStorage.removeItem('dataReading');
    window.location.href = this.apiUrlLoginRegresa;

  }
  ValidateAuthentication()
  {
    if(this.getToken()) return true
    return false;
  }
  private setToken(jwt: string) {
    this.setItem('jwt', jwt);
  }
  getToken(): string {
    
    return this.getItem('jwt') || '';
  }
  private setDataReading(dataReading: string) {
    this.setItem('dataReading', dataReading);
  }
  GetDataReading(): string {
    
    return this.getItem('dataReading') || '';
  }
}



