import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { Observable, of, Subscription } from "rxjs";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  general: any;
  moduleSubscription: Subscription = new Subscription();
  actionLecturaSubscription: Subscription = new Subscription();
  queryParams = null;
  //lectura:any=undefined
  //queryParamsSubscription: Subscription = new Subscription();
  get apiUrl(): string {
    return environment.apiUrl;
  }
  constructor(private httpClient: HttpClient, private store: Store<any>) {
    this.moduleSubscription = this.store
      .select((state) => state?.general)
      .subscribe((general) => (this.general = general));
  }
  //valda si exiet datos
  getExists(id, general, actionResult) {
    if (id && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + general + "/" + actionResult}/${id}`
      );
    }
    return this.httpClient.get(`${this.apiUrl + "/" + general}/${id}`);
  }
  //obtiene todos los datos
  getItems() {
    let params = new HttpParams();
    params = this.general.index.id
      ? params.append("id", this.general.index.id)
      : params;
    params = this.general.index.quantity
      ? params.append("quantity", this.general.index.quantity)
      : params;
    params =
      this.general.index.page != null && this.general.index.page != undefined
        ? params.append("page", this.general.index.page)
        : params;
    params =
      this.general.index.search && this.general.index.search.length > 0
        ? params.append("search", this.general.index.search)
        : params;
    params = this.general.index.orderBy
      ? params.append("orderby", this.general.index.orderBy)
      : params;
    params = this.general.index.orderType
      ? params.append(
          "ascdesc",
          this.general.index.orderType == "asc" ? "ascending" : "descending"
        )
      : params;
    params = this.general.index.filtros
      ? params.append("filtrosJSON", JSON.stringify(this.general.index.filtros))
      : params;
    params = this.general.index.parameters
      ? params.append(
          "parameters",
          JSON.stringify(this.general.index.parameters)
        )
      : params;
    if (this.general?.modulo?.actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + this.general?.modulo?.name}/${
          this.general?.modulo?.actionResult
        }`,
        { params }
      );
    } else {
      return this.httpClient.get(
        `${this.apiUrl + "/" + this.general?.modulo?.name}/`,
        { params }
      );
    }
  }
  //obtiene todos los datos para descarga de los reportes
  getItemsAll(quantity) {
    let params = new HttpParams();
    params = params.append("page", "0");
    params = params.append("quantity", quantity);
    params =
      this.general.index.search && this.general.index.search.length > 0
        ? params.append("search", this.general.index.search)
        : params;
    params = this.general.index.orderBy
      ? params.append("orderby", this.general.index.orderBy)
      : params;
    params = this.general.index.orderType
      ? params.append(
          "ascdesc",
          this.general.index.orderType == "asc" ? "ascending" : "descending"
        )
      : params;
    params = this.general.index.filtros
      ? params.append("filtrosJSON", JSON.stringify(this.general.index.filtros))
      : params;
    params = this.general.index.parameters
      ? params.append(
          "parameters",
          JSON.stringify(this.general.index.parameters)
        )
      : params;

    if (this.general?.modulo?.actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + this.general?.modulo?.name}/${
          this.general?.modulo?.actionResult
        }`,
        { params }
      );
    } else {
      return this.httpClient.get(
        `${this.apiUrl + "/" + this.general?.modulo?.name}/`,
        { params }
      );
    }
  }
  //lista los datos id y nombre de acion
  GetByIdList(id?, id1?, module?, actionResult?) {
    let params = new HttpParams();
    params = id ? params.append("id", id) : params;
    params = id1 ? params.append("id1", id1) : params;
    if (id && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}`,
        { params }
      );
    }
    if (id && id1 && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}`,
        { params }
      );
    }
    if (id && id1 && !actionResult) {
      return this.httpClient.get(`${this.apiUrl + "/" + module}`, { params });
    } else if (id && !actionResult) {
      //params = params.append('id', id);
      return this.httpClient.get(`${this.apiUrl + "/" + module}/${id}`);
    } else if (!id && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}`
      );
    }
    return this.httpClient.get(`${this.apiUrl + "/" + module}`);
  }

  //LISTA POR ID PRINCIPAL ID
  GetListById(id?, module?, actionResult?) {
    let params = new HttpParams();
    params = id ? params.append("id", id) : params;
    if (id && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}`,
        { params }
      );
    }
    if (id && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}`,
        { params }
      );
    }
    if (id && !actionResult) {
      return this.httpClient.get(`${this.apiUrl + "/" + module}`, { params });
    } else if (id && !actionResult) {
      //params = params.append('id', id);
      return this.httpClient.get(`${this.apiUrl + "/" + module}/${id}`);
    } else if (!id && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}`
      );
    }
    return this.httpClient.get(`${this.apiUrl + "/" + module}`);
  }

  //lista los datos del formulario
  GetDataForm(
    id = undefined,
    id1 = undefined,
    module = undefined,
    actionResult = undefined,
    parameters = undefined
  ) {
    let params = new HttpParams();
    params = id ? params.append("id", id) : params;
    params = id1 ? params.append("id1", id1) : params;
    params = parameters
      ? params.append("parameters", JSON.stringify(parameters))
      : params;
    if (id && !id1 && !actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/GetData"}?id=${id}`
      );
    } else if (id && !id1 && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}?id=${id}`
      );
    } else if (id && id1 && !actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/GetData"}/`,
        { params }
      );
    } else if (id && id1 && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}/`,
        { params }
      );
    } else if (!id && id1 && !actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/GetData"}/`,
        { params }
      );
    } else if (!id && parameters && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}`,
        { params }
      );
    } else if (!id && !parameters && actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}`,
        { params }
      );
    } else if (!id && !id1 && !actionResult && parameters) {
      return this.httpClient.get(`${this.apiUrl + "/" + module + "/GetData"}`, {
        params,
      });
    } else if (!id && !id1 && actionResult && parameters) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/GetData/" + actionResult}`,
        { params }
      );
    }
    return this.httpClient.get(`${this.apiUrl + "/" + module + "/GetData"}`);
  }

  GetItemPagination(id, module, actionResult, quantity, page, searchText) {
    let params = new HttpParams();
    //params = params.append('id', id);
    //params = params.append('quantity', quantity);
    params = id > 0 ? params.append("id", id) : params;
    params = page ? params.append("page", page) : params;
    params =
      searchText && searchText.length > 0
        ? params.append("searchText", searchText)
        : params;
    if (actionResult) {
      return this.httpClient.get(
        `${this.apiUrl + "/" + module + "/" + actionResult}`,
        { params }
      );
    }
    return this.httpClient.get(`${this.apiUrl + "/" + module}`, { params });
  }
  Login(user: any) {
    return this.httpClient.post(`${this.apiUrl}/Auth/Token`, user);
  }
  postItem(data, general, formData = false) {
    if (formData) {
      var form_data = new FormData();
      for (var key in data) {
        form_data.append(key, data[key]);
      }
      return this.httpClient.post(`${this.apiUrl + "/" + general}`, form_data);
    } else {
      return this.httpClient.post(`${this.apiUrl + "/" + general}`, data);
    }
  }

  putItem(id, data, general, formData = false) {
    if (formData) {
      var form_data = new FormData();
      for (var key in data) {
        form_data.append(key, data[key]);
      }
      return this.httpClient.put(
        `${this.apiUrl + "/" + general}?id=${id}`,
        form_data
      );
    } else {
      return this.httpClient.put(
        `${this.apiUrl + "/" + general}?id=${id}`,
        data
      );
    }
  }

  deleteItems(general: string, actionResult: string, ids: any) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: ids,
    };

    if (ids && actionResult) {
      return this.httpClient.delete(
        `${this.apiUrl + "/" + general + "/" + actionResult}`,
        options
      );
    }
    return this.httpClient.delete(`${this.apiUrl + "/" + general}`, options);
  }

  //anular datos
  anularItems(general: string, actionResult: string, ids: any) {
    /*let params ;
  if(parameters)
  {
    params = new HttpParams();
    params = parameters ? params.append('parameters', JSON.stringify(parameters)) : params;  
  }
  else
  {
    params=ids;
  }*/
    if (ids && actionResult) {
      return this.httpClient.post(
        `${this.apiUrl + "/" + general + "/" + actionResult}`,
        ids
      );
    }
    return this.httpClient.post(`${this.apiUrl + "/" + general}`, ids);
  }

  //datos por id y fecha

  datosDiasFecha(s07Id: any, s02Id: any, desde: any, hasta: any) {
    return this.httpClient.get(
      this.apiUrl +
        "/RegistroUsuarioProyecto/pruebaDias?" +
        "s07Id=" +
        s07Id +
        "&s02Id=" +
        s02Id +
        "&desde=" +
        desde +
        "&hasta=" +
        hasta
    );
  }

  mostardatosDiasFechaModel(s07Id: any, s02Id: any, fecha: any) {
    return this.httpClient.get(
      this.apiUrl +
        "/RegistroUsuarioProyecto/editarDiaFecha?" +
        "s07Id=" +
        s07Id +
        "&s02Id=" +
        s02Id +
        "&fecha=" +
        fecha
    );
  }

  deleteDia(s07Id: any, s02Id: any, fecha: any) {
    return this.httpClient.delete(
      this.apiUrl +
        "/RegistroUsuarioProyecto/DeleteDias?" +
        "s07Id=" +
        s07Id +
        "&s02Id=" +
        s02Id +
        "&fecha=" +
        fecha
    );
  }

  verificarDia(s07Id: any, s02Id: any, fecha: any) {
    return this.httpClient.get(
      this.apiUrl +
        "/RegistroUsuarioProyecto/GetVerificarDia?" +
        "S07Id=" +
        s07Id +
        "&S02Id=" +
        s02Id +
        "&fecha=" +
        fecha
    );
  }

  reporteConsultor(iS02Id: any, iAnio: any, iMes: any) {
    return this.httpClient.get(
      this.apiUrl +
        "/RegistroUsuarioProyecto/NewGetAllReportConsultor?" +
        "iS02Id=" +
        iS02Id +
        "&iAnio=" +
        iAnio +
        "&iMes=" +
        iMes
    );
  }

  datosGrafica(idProyecto: any) {
    return this.httpClient.get(
      this.apiUrl + "/Proyecto/DatosGrafica?" + "idProyecto=" + idProyecto
    );
  }

  datosGraficaSemanal(idProyecto: any) {
    return this.httpClient.get(
      this.apiUrl +
        "/Proyecto/DatosGraficaSemanal?" +
        "idProyecto=" +
        idProyecto
    );
  }
  DatosGraficaSaldoProyecto(idProyecto: any) {
    return this.httpClient.get(
      this.apiUrl +
        "/Proyecto/DatosGraficaSaldoProyecto?" +
        "idProyecto=" +
        idProyecto
    );
  }

  clientes() {
    return this.httpClient.get(this.apiUrl + "/ClienteProveedor");
  }

  consultores() {
    return this.httpClient.get(this.apiUrl + "/Colaborador");
  }

  GetStatusProyectos(id: any): Observable<any> {
    return this.httpClient.get<any>(
      this.apiUrl + "/RegistroUsuarioProyecto/mostrasStatusProyecto?id=" + id
    );
  }
}
