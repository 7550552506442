import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDateFormat'
})
export class MomentDateFormatPipe implements PipeTransform {

  transform(value: any, ...args: string[]): string {
    return value ? moment(value).locale("es").format(args[0]).toUpperCase() : "Error en el formato";
  }

}
