import { Component, OnDestroy, OnInit } from "@angular/core";
@Component({
  selector: "ngx-app",
  template:
    '<ng-progress thick="true" color="#fff"></ng-progress><router-outlet> </router-outlet>',
})
export class AppComponent implements OnInit, OnDestroy {
  constructor() {}
  ngOnInit(): void {}
  ngOnDestroy() {}
}
