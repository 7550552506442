import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as uuid from 'uuid';
import * as generalActions from '../../ngrx/general.actions'
import { Botton_action } from 'app/@components/confi/confi';
@Component({
  selector: 'ngx-crud-buttom-add',
  templateUrl: './crud-buttom-add.component.html',
  styleUrls: ['./crud-buttom-add.component.scss']
})
export class CrudButtomAddComponent implements OnInit, OnDestroy {
  Botton_action=Botton_action
  constructor(    
    private store: Store<any>,
    ) {
   }
  ngOnInit(): void {
  }
  ngOnDestroy(){
  }
  openPopUp(item?:any, id?:any)
  {
    this.store.dispatch(generalActions.SetActionCreateAction({ actionCreate: uuid.v4() }));
  }
}
