import { Directive, ElementRef, HostListener, Output, EventEmitter, Input } from '@angular/core';

@Directive({
  selector: '[appCompleteCerosIzquierda]'
})
export class CompleteCerosIzquierdaDirective {

  @Output() ngModelChange = new EventEmitter();
  @Input("appCompleteCerosIzquierda") ceros: number;

  constructor(
    private elementRef: ElementRef
  ) { }

  @HostListener('blur', ['$event']) setInputFocusOut(event) {
    const resEventValue = event.target.value;
    let cadenaCeros = '';

    for (let i = 0; i < this.ceros; i++) {
      cadenaCeros += '0';
    }

    if (resEventValue != null && resEventValue != undefined && resEventValue != '') {
      this.elementRef.nativeElement.value = (cadenaCeros + `${resEventValue}`).slice((this.ceros*-1));
    }

    this.ngModelChange.emit(this.elementRef.nativeElement.value);
  }

}
