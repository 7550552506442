<ngx-crud-escifica-empresa-emp>
  <div class="crud-table-container">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortData($event)"
      class="ngx-crud mat-elevation-z8"
    >
      <ng-container matColumnDef="fP01RazonSocial">
        <th width="30%" mat-header-cell *matHeaderCellDef mat-sort-header>
          Razon Social
        </th>
        <td width="30%" mat-cell *matCellDef="let element">
          {{ element.fP01RazonSocial }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fP01Ruc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ruc</th>
        <td mat-cell *matCellDef="let element">{{ element.fP01Ruc }}</td>
      </ng-container>

      <ng-container matColumnDef="fP01Direccion">
        <th width="30%" mat-header-cell *matHeaderCellDef mat-sort-header>
          Direccion
        </th>
        <td width="30%" mat-cell *matCellDef="let element">
          {{ element.fP01Direccion }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fP01Telefono">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono</th>
        <td mat-cell *matCellDef="let element">{{ element.fP01Telefono }}</td>
      </ng-container>

      <ng-container matColumnDef="Acciones">
        <th mat-header-cell *matHeaderCellDef>Acciones</th>
        <td mat-cell *matCellDef="let row">
          <div class="buttons-row">
            <button
              type="button"
              size="small"
              nbTooltip="{{ Botton_action.Editar.descripcion }}"
              nbButton
              matRipple
              class="mr-1"
              (click)="insertEditDataPopup(row.fP01Id)"
            >
              <nb-icon class="action-icon" icon="edit"></nb-icon>
            </button>
            <button
              type="button"
              size="small"
              nbTooltip="{{ Botton_action.Ver.descripcion }}"
              nbButton
              matRipple
              (click)="detailPopUp(row.fP01Id)"
              class="mr-1"
            >
              <nb-icon class="action-icon" icon="eye"></nb-icon>
            </button>
            <button
              *ngIf="!lectura"
              type="button"
              size="small"
              nbTooltip="{{ Botton_action.Eliminar.descripcion }}"
              nbButton
              matRipple
              (click)="deletePopUp(row)"
            >
              <nb-icon class="action-icon" icon="trash-2"></nb-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    [length]="length"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
    [showFirstLastButtons]="true"
    (page)="changePagination($event)"
  >
  </mat-paginator>
</ngx-crud-escifica-empresa-emp>
