/*export const environment = {
  production: false,
  apiUrl: "https://localhost:5002/api", //consume la api principal
  apiUrlLogin: "https://localhost:5002/api", //consume login y menu
  apiUrlLoginRegresa: "https://accesos.ceer.app/", //regresa al login principa
};
*/

export const environment = {
  production: true,
  apiUrl: "https://erp.ceer.app/api", //consume la api principal
  //apiUrl: "http://192.168.40.130:9092/api", //testing
  apiUrlLogin: "https://accesos.ceer.app/api", //consume login y menu
  apiUrlLoginRegresa: "https://accesos.ceer.app", //regresa al login principal
};
