import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatTimer"
})
export class FormatTimerPipe implements PipeTransform {
  transform(value: string, limite: string): string 
  {
    const limit=parseInt(limite);
    
    if(value!==null)
    {
      return value.length > limit?value.substring(0, limit):value;
    }
    return value;
    
  }

}
