
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable,throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthTokenService } from './authToken.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, 
    private authService:AuthTokenService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = this.authService.getToken();
    const authRequest=req.clone({setHeaders:{authorization:`Bearer ${jwt}`}});
    
    return next.handle(authRequest).pipe(
        catchError((err, caugth)=>
        {
            if(err.status===401)
            {
            this.router.navigate(['/sessions/signin'],{
             queryParams:{redirectUrl: this.router.routerState.snapshot.url},});}
        return observableThrowError(err);
        })
    ); 
  
  }
}
