
<div class="altoTituloPopop">
  <span class="titulo"> {{data?.title }}  </span>
  <span fxFlex></span>
  <button type="button" mat-icon-button class="mt--10" (click)="dialogRef.close()">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12 col-md-6  pr-md-6">
        <mat-form-field class="input-example" >
          <mat-label class="label">Seleccionar Empresa </mat-label>
          <mat-select (selectionChange)="search($event.value)" >
            <mat-option *ngFor="let item of empresas " [value]="item">{{item?.fS07NombreComercial}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="mr-1" type="button" matRipple nbButton status="disabled"
    (click)="dialogRef.close(false)">{{Botton_action.Cancelar.nombre}}</button>
  <button nbButton matRipple status="primary" (click)="submit()">{{Botton_action.Continuar.nombre}}</button>
</mat-dialog-actions>



