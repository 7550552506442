<div class="altoTituloPopop">
    <span class="titulo">  {{data.title }} {{modules.clienteProveedor.title}}</span> 
    <span fxFlex></span>
    <button type="button" mat-icon-button class="mt--10" (click)="dialogRef.close()">
      X
     </button>
  </div>
  <mat-dialog-content class="mat-typography" >
    <div class="form-group row">
      <label  class="label col-sm-3 col-form-label">Razon Social</label>
      <div class="col-sm-9">
        {{item?.fP01RazonSocial}}
      </div>
    </div>
    <div class="form-group row">
      <label  class="label col-sm-3 col-form-label">Direccion</label>
      <div class="col-sm-9">
        {{item?.fP01Direccion}}
      </div>
    </div>
    <div class="form-group row">
        <label  class="label col-sm-3 col-form-label">Telefono</label>
        <div class="col-sm-9">
          {{item?.fP01Telefono}}
        </div>
      </div>
      <div class="form-group row">
        <label  class="label col-sm-3 col-form-label">Correo Electronico</label>
        <div class="col-sm-9">
          {{item?.fP01CorreoElectronico}}
        </div>
      </div>
      <div class="form-group row">
        <label  class="label col-sm-3 col-form-label">Ruc</label>
        <div class="col-sm-9">
          {{item?.fP01Ruc}}
        </div>
      </div>
      <div class="form-group row">
        <label  class="label col-sm-3 col-form-label">Tipo </label>
        <div class="col-sm-9">
          {{item?.fP01Tipo}}
        </div>
      </div>  
    <div class="form-group row">
      <label  class="label col-sm-3 col-form-label">Cliente</label>
      <div class="col-sm-9">
        {{item?.fP01Cliente? 'Activo' : 'Inactivo'}}
      </div>
    </div>
    <div class="form-group row">
        <label  class="label col-sm-3 col-form-label">Proveedor</label>
        <div class="col-sm-9">
          {{item?.fP01Proveedor? 'Activo' : 'Inactivo'}}
        </div>
      </div>
    <div class="form-group row">
      <label  class="label col-sm-3 col-form-label">Estado</label>
      <div class="col-sm-9">
        {{item?.fP01Activo ? 'Activo' : 'Inactivo'}}
      </div>
    </div>
    <div class="form-group row">
        <label  class="label col-sm-3 col-form-label">Proveedor Evaluado</label>
        <div class="col-sm-9">
          {{item?.fP01ProveedorEvaluado? 'Si' : 'No'}}
        </div>
      </div>
  </mat-dialog-content>
  
  
  
  
  
  <mat-dialog-actions align="end">
    <button class="mr-1" type="button" size="small" matRipple nbButton status="disabled" (click)="dialogRef.close(false)">{{Botton_action.Cancelar.nombre}}</button>
  </mat-dialog-actions>
  
  
  
  
  
  
  
  
  