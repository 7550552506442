
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import *as generalActions from '../@components/general/ngrx/general.actions'
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, 
    private store: Store<any>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler)
  {
    /*const jwt = this.authService.getToken();
    const authRequest=req.clone({setHeaders:{authorization:`Bearer ${jwt}`}});
    
    return next.handle(authRequest).pipe(
        catchError((err, caugth)=>
        {
            if(err.status===401)
            {
            this.router.navigate(['/sessions/signin'],{
             queryParams:{redirectUrl: this.router.routerState.snapshot.url},});}
        return observableThrowError(err);
        })
    ); */

   return next.handle(req)
      .pipe(catchError(error => {
        
        if (error.status === 401)
        {
          this.router.navigate(['auth/login']);
          return throwError(error);
        }
        else
        {
        this.store.dispatch(generalActions.SetErrorAction(error));
         return of(error);
        }
       
        //return throwError(error);
      }));
  }
}
