import { createAction, props } from '@ngrx/store';

export const ClearStorageAction = createAction('[AUTH] CLEAR STORAGE');
//Login get
export const GetAuthAction = createAction('[AUTH] GET TOKEN',
props<{auth:any }>());
//Login set
export const SetAuthAction = createAction('[AUTH] SET TOKEN',
props<{ auth: any}>());
//Login get
export const GetAuthLocalStorageAction = createAction('[AUTH] GET TOKEN LOCALSTORE',
props<{auth:any }>());
//Login set
export const SetAuthLocalStorageAction = createAction('[AUTH] SET TOKEN LOCALSTORE',
props<{ auth: any}>());

export const SetUserAction = createAction('[AUTH] SET DATA USER',
props<{ user: any}>());