import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Botton_action, modules } from "app/@components/confi/confi";
import { Subscription } from "rxjs";
import * as uuid from "uuid";
import * as indexActions from "app/@components/general/ngrx/index/index.actions";

@Component({
  selector: "registroAsignacionProyecto1PopupAddUpd",
  templateUrl: "registroAsignacionProyecto1PopupAddUpd.component.html",
  styleUrls: ["registroAsignacionProyecto1PopupAddUpd.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RegistroAsignacionProyecto1PopupAddUpdComponent
  implements OnInit, OnDestroy
{
  public itemForm: FormGroup;
  Botton_action = Botton_action;
  modules = modules;
  popupId = uuid.v4();
  uuidResponseSub: Subscription = new Subscription();
  objectSub: Subscription = new Subscription();
  id: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RegistroAsignacionProyecto1PopupAddUpdComponent>,
    private store: Store<any>,
    private fb: FormBuilder
  ) {}
  ngOnInit() {
    this.buildItemForm();
    this.id = this.data.id;
    if (this.id > 0) {
      this.store.dispatch(
        indexActions.GetByIdAction({
          id: this.id,
          module: "registroUsuarioProyecto",
          actionResult: undefined,
          popupId: this.popupId,
        })
      );
    } else {
      this.buildItemForm();
    }
    this.objectSub = this.store
      .select((state) => state?.general?.item[this.popupId]?.object)
      .subscribe((object) => {
        if (object) {
          this.itemForm.patchValue(object);
        }
      });

    this.uuidResponseSub = this.store
      .select((state) => state?.general?.item[this.popupId]?.uuid)
      .subscribe((uuid) => {
        if (uuid) {
          this.close();
        }
      });
  }

  ngOnDestroy(): void {
    this.uuidResponseSub.unsubscribe();
    this.objectSub.unsubscribe();
    this.store.dispatch(
      indexActions.ClearStorageAction({ popupId: this.popupId })
    );
  }
  get fP12Nombre() {
    return this.itemForm.get("fP12Nombre");
  }
  buildItemForm() {
    this.itemForm = this.fb.group({
      fP12Id: [this.id],
      fP12Nombre: ["", Validators.required],
      fP12Detalle: ["", Validators.maxLength(150)],
    });
  }
  close(): void {
    this.dialogRef.close();
  }

  continuar() {
    this.dialogRef.close(1);
  }

  save() {
    if (this.itemForm.invalid) {
      this.itemForm.markAllAsTouched();
      return;
    }
    if (this.itemForm.value && this.id == 0) {
      this.store.dispatch(
        indexActions.PostAction({
          data: this.itemForm.value,
          module: "registroUsuarioProyecto",
          popupId: this.popupId,
        })
      );
    } else {
      this.store.dispatch(
        indexActions.PutAction({
          id: this.data.id,
          data: this.itemForm.value,
          module: "registroUsuarioProyecto",
          popupId: this.popupId,
        })
      );
    }
  }
}
