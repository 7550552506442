
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxValidationMessageComponent } from './validation-message/validation-message.component';
import { NbAlertModule, NbButtonModule, NbCardModule, NbIconModule, NbMenuModule, NbSpinnerModule, NbTooltipModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as generalReducer from './general/ngrx/general.reducer';
import { GeneralEffects } from './general/ngrx/general.effects';
import { IndexEffects } from './general/ngrx/index/index.effects';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSidenavModule } from '@angular/material/sidenav';
import { CrudComponent } from './general/componentes-crud/crud/crud.component'
import { CrudButtomAddComponent } from './general/componentes-crud/crud-buttom-add/crud-buttom-add.component'
import { CrudTitleComponent } from './general/componentes-crud/crud-title/crud-title.component'
import { CrudSearchComponent } from './general/componentes-crud/crud-search/crud-search.component'
import { ErrorPopupComponent } from './general/error-pupup/error-pupup.component'
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxValidationMessageCrudComponent } from './validation-message-crud/validation-message-crud.component'
import { MatSelectModule } from '@angular/material/select';
import { CrudDeletePopupComponent } from './general/componentes-crud/crud-delete-popup/crud-delete-popup.component';
import { CrudAnularPopupComponent } from './general/componentes-crud/crud-anular-popup/crud-anular-popup.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateFormatPipe } from './pipes/momentDate.pipe'
import { MaskIntegerPositivoDirective } from 'app/@components/directives/mask-integer-positivo.directive'
import { MaskDecimalDirective } from 'app/@components/directives/mask-decimal.directive'
import { MaskDecimalPositivoDirective } from 'app/@components/directives/mask-decimal-positivo.directive'
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CrudReportesComponent } from './general/componentes-crud-reportes/crud-reportes/crud-reportes.component';
import { CrudEspecificaEmpresaComponent } from './general/componentes-crud/crud-escifica-empresa/crud-escifica-empresa.component';
import { CrudEspecificaEmpresaEmpComponent } from './general/componentes-crud/crud-escifica-empresa-emp/crud-escifica-empresa-emp.component';

//Crud Empresa Banco
import { CrudSearchIngresoEgresoComponent } from './general/componentes-crud/crud-search-ingresoEgreso/crud-search-ingresoEgreso.component';

import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FormatTimerPipe } from './pipes/formatTimer.pipe'
import { UtilService } from './services/util.service';
import { CompleteDecimalsDirective } from 'app/@components/directives/complete-decimals.directive';
import { InputMaxNumberDirective } from 'app/@components/directives/input-max-number.directive';
import { MaskDecimalNegativoDirective } from 'app/@components/directives/mask-decimal-negativo.directive';
import { CompleteCerosIzquierdaDirective } from 'app/@components/directives/complete-ceros-izquierda.directive';
import { CrudSelectEmpresaComponent } from './general/componentes-crud/crud-select-empresa/crud-select-empresa.component';
import { CrudEmpresaComponent } from './general/componentes-crud/crud-empresa/crud-empresa.component';
import { CrudNoButtonComponent } from './general/componentes-crud/crud-no-button/crud-no-button.component';
import { CrudWithoutSearchComponent } from './general/componentes-crud/crud-without-search/crud-without-search.component';

const COMPONENTS = [
  NgxValidationMessageComponent,
  NgxValidationMessageCrudComponent,
  ErrorPopupComponent,
  MomentDateFormatPipe,
  FormatTimerPipe,
  MaskIntegerPositivoDirective,
  MaskDecimalDirective,
  MaskDecimalPositivoDirective,
  CompleteDecimalsDirective,
  InputMaxNumberDirective,
  MaskDecimalNegativoDirective,
  CompleteCerosIzquierdaDirective
];

const componentsCrud = [
  CrudComponent,
  CrudButtomAddComponent,
  CrudTitleComponent,
  CrudSearchComponent,
  CrudDeletePopupComponent,
  CrudReportesComponent,
  CrudEspecificaEmpresaComponent,
  CrudEspecificaEmpresaEmpComponent,
  CrudSelectEmpresaComponent,
  CrudAnularPopupComponent,
  CrudEmpresaComponent,
  CrudNoButtonComponent,
  CrudWithoutSearchComponent,
  //Crud Empresa Banco
  CrudSearchIngresoEgresoComponent
]
const componentsCrudExport = [
  CrudComponent,
  CrudReportesComponent,
  CrudDeletePopupComponent,
  CrudEspecificaEmpresaComponent,
  CrudEspecificaEmpresaEmpComponent,
  CrudSelectEmpresaComponent,
  CrudAnularPopupComponent,
  CrudEmpresaComponent,
  CrudNoButtonComponent,
  CrudWithoutSearchComponent,
  //Crud IngresoEgreso
  CrudSearchIngresoEgresoComponent,
  CrudSearchComponent,
]
const materialModules = [
  ReactiveFormsModule,
  FlexLayoutModule,
  NgxDatatableModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatCardModule,
  MatTableModule,
  MatSortModule,
  MatPaginatorModule,
  MatSidenavModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatExpansionModule,
  NgxMatSelectSearchModule,
  MatTooltipModule,
  NgxMaterialTimepickerModule
];
const nbModules = [
  NbCardModule,
  NbIconModule,
  NbTooltipModule,
  NbEvaIconsModule,
  NbButtonModule,
  NbMenuModule,
  NbAlertModule,
  NbSpinnerModule,
];
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(generalReducer.GeneralFeatureKey, generalReducer.reducer),
    EffectsModule.forFeature([GeneralEffects, IndexEffects]),
    ...nbModules,
    ...materialModules,
  ],
  exports: [...COMPONENTS, ...materialModules, ...nbModules, componentsCrudExport],
  declarations: [...COMPONENTS, componentsCrud],
  entryComponents: [UtilService
  ],
})
export class ComponentsModule {
}
