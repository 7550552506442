
import { Injectable } from '@angular/core';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { of } from 'rxjs';
import { showErrorService } from "./showError.service"
@Injectable({
  providedIn: 'root'
})
export class ShowToastService {
  duration = 5000;
  destroyByClick = true;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  constructor(private toastrService: NbToastrService,
    private showErrorService: showErrorService) {
  }
  showToast(type?: NbComponentStatus, title?: string, body?: string) {
    const hasIcon = this.hasIcon ? {} : { icon: '' };
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
      ...hasIcon,
    };
    const titleContent = title ? ` ${title}` : '';
    this.toastrService.show(
      body, `${titleContent}`, config);
    return of();
  }
  error(error) {
    let compararMensaje = error?.error?.message[0]
    if (compararMensaje == "555") {
      return of("555");
    }
    if (compararMensaje == "444") {
      return of("444");
    }
    /*let respuesta=this.respuestaService.successInsert();
                this.showToast('danger', respuesta.message, respuesta.detalle);*/
    this.showErrorService.showError(error)
    return of(error);
  }
}
