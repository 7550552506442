

<div class="altoTituloPopop">
  <span class="titulo"> {{data?.title }}  </span>
  <span fxFlex></span>
  <button type="button" mat-icon-button class="mt--10" (click)="dialogRef.close()">
    <mat-icon>
      close
    </mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <nb-alert outline="danger">
          <span>Se ha producido un Error</span>
          <br>
          <div *ngIf="data && data.listaError && data.listaError.length > 0">
            <hr />
            <ul>
                <li *ngFor="let error of data.listaError">{{ error }}</li>
            </ul>
        </div>
        <div *ngIf="data.mainMessage">
          <p>
            {{data.mainMessage}}
          </p>
        </div>
        
          <br>
          <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Ver detalle
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="alert alert-danger">
                    <div class="mensaje-error" [innerHTML]="data.message"></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        
            
       
        </nb-alert>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="mr-1" type="button" size="small" matRipple nbButton status="disabled"
    (click)="dialogRef.close(false)">{{Botton_action.Cancelar.nombre}}</button>
</mat-dialog-actions>



