import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
@Component({
  selector: 'crud-reportes',
  templateUrl: './crud-reportes.component.html',
  styleUrls: ['./crud-reportes.component.scss']
})
export class CrudReportesComponent implements OnInit, OnDestroy {
  constructor(  ) { }
 
  ngOnInit(): void {
   /* this.loadingSub = this.store.select(state => state.general.loading)
    .subscribe(loading => {
     
      if (loading != null && loading != undefined) 
      {
        this.loading = loading;
        this.cd.detectChanges();
      }
    });*/
  }
  ngOnDestroy(): void {
  }
}
