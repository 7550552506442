import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import { ClienteProveedorComponent } from "./view/erp/clienteProveedor/clienteProveedor.component";
const routes: Routes = [
  {
    path: "view",
    loadChildren: () =>
      import("app/view/view.module").then((m) => m.ViewModule),
  },
  {
    path: "auth",
    loadChildren: () =>
      import("app/@auth/auth.module").then((m) => m.AuthModule),
  },
  { path: "", redirectTo: "auth/logout", pathMatch: "full" },
  { path: "**", redirectTo: "auth/logout" },
  {
    path: "ejemplo",
    component: ClienteProveedorComponent,
  },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
