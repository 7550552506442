import * as authActions from './auth.actions';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';
import {AuthTokenService} from '../authToken.service'
import { HttpService } from 'app/@components/general/services/http.service';
import { Router } from '@angular/router';
import { RespuestaService } from 'app/@components/respuesta.service';
import { ShowToastService } from 'app/@components/showToast.service';
@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private httpService: HttpService,
    private authTokenService:AuthTokenService,
    protected router: Router,
    private respuestaService: RespuestaService,
    private showToastService:ShowToastService
  ) { }

  GetItemsAction$ = createEffect(() =>
  this.actions$.pipe(
    ofType(authActions.GetAuthAction),
    switchMap((action) =>
      this.httpService.Login(action.auth).pipe(
        map((res: any) => {
          this.authTokenService.SaveUserLocalStore(res.data); 
          
          if(res.data.menus.length==0)
          {
            //this.store.dispatch(generalActions.SetErrorWithOutAction());
            let respuesta=this.respuestaService.withOutmenu(res.data.aS02Nombre+' '+res.data.aS02Apellido);
           this.showToastService.showToast('warning', respuesta.message, respuesta.detalle);
          }
       return   authActions.SetAuthAction({ auth: res.data, })
        })
      ))));
      SetAutheAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.SetAuthAction),
      map(() => {
       // return this.router.navigateByUrl('/view');
      })), { dispatch: false });
}

