

import { Injectable } from '@angular/core';
import { NbJSThemesRegistry, NbThemeService } from '@nebular/theme';

@Injectable()
export class InitUserService {
    constructor(
        protected jsThemes: NbJSThemesRegistry,
        protected themeService: NbThemeService) { }
}
