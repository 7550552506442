export const Botton_action = {
  Ver: {
    nombre: "VER",
    titulo: "Ver detalle",
    descripcion: "Ver detalle",
  },
  Pagos: {
    nombre: "Pagos",
    titulo: "Ver Pagos",
    descripcion: "Ver pagos",
  },
  Crear: {
    nombre: "AÑADIR",
    titulo: "Añadir",
    descripcion: "Añadir",
  },
  Alerta: {
    nombre: "ALERTA",
    titulo: "Alerta",
    descripcion: "Alerta",
  },
  Editar: {
    nombre: "EDITAR",
    titulo: "Editar",
    descripcion: "Editar",
  },
  Eliminar: {
    nombre: "ELIMINAR",
    titulo: "Eliminar",
    descripcion: "Eliminar",
  },
  EliminarLista: {
    nombre: "ELIMINAR",
    titulo: "Eliminar",
    descripcion: "Eliminar toda la lista relacionada",
  },
  Cancelar: {
    nombre: "CANCELAR",
    titulo: "Cancelar",
    descripcion: "Cancelar",
  },
  Guardar: {
    nombre: "GUARDAR",
    titulo: "Guardar",
    descripcion: "Guardar",
  },
  Aceptar: {
    nombre: "ACEPTAR",
    titulo: "Aceptar",
    descripcion: "Aceptar",
  },
  Agregar: {
    nombre: "AGREGAR",
    titulo: "Agregar",
    descripcion: "Agregar",
  },
  Buscar: {
    nombre: "BUSCAR",
    titulo: "Buscar",
    descripcion: "Buscar",
  },
  ExportarExcel: {
    nombre: "EXPORTAR EXCEL",
    titulo: "Exportar Excel",
    descripcion: "Exportar Excel",
  },
  ExportarPdf: {
    nombre: "EXPORTAR PDF",
    titulo: "Exportar Pdf",
    descripcion: "Exportar Pdf",
  },
  Continuar: {
    nombre: "CONTINUAR",
    titulo: "Continuar",
    descripcion: "Continuar",
  },
  Imprimir: {
    nombre: "IMPRIMIR",
    titulo: "Imprimir",
    descripcion: "Imprimir",
  },
  SubirXml: {
    nombre: "SUBIR XML",
    titulo: "Subir xml",
    descripcion: "Subir xml de facturas",
  },
  //Botón para subir archivos CSV
  SubirCSV: {
    nombre: "SUBIR CSV",
    titulo: "Subir csv",
    descripcion: "Subir csv de transacciones",
  },
  //Botón para descargar archivos
  DescargarArchivo: {
    nombre: "DESCARGAR ARCHIVO",
    titulo: "Descargar Archivo",
    descripcion: "Descargar archivos almacenados",
  },
  Anular: {
    nombre: "ANULAR",
    titulo: "Anular",
    descripcion: "Anular ",
  },
  Desanulacion: {
    nombre: "DESANULACIÓN",
    titulo: "Desanulación",
    descripcion: "Desanulación ",
  },
  Desanular: {
    nombre: "DESANULAR",
    titulo: "Desanular",
    descripcion: "Desanular ",
  },
};

export const modal_size = {
  SM: "400px",
  MD: "700px",
  LG: "1000px",
  XL: "1400px",
  XXL: "1920px",
};
export const modal_position = {
  top: "30px",
};

export const buscar_por_columna = {
  buscar: "Buscar datos  por columna",
};
export const pagination_controls = {
  siguiente: "Siguiente",
  anterior: "Anterior",
};
export const lista_ide = {
  titulo: "LISTA DE",
};

export const titulos = {
  detallePopop: "DETALLE",
  eliminarPopop: "ELIMINAR",
  AnularPopop: "ANULAR",
  seleccionarPermisos: "SELECCIONAR PERMISOS",
  seleccionarItems: "SELECCIONAR ITEMS",
  seleccionarMenus: "SELECCIONAR MENUS",
  eliminarPopopDetallePerfil: "ELIMINAR DETALLE",
  confirmarEliminacion: "Confirmar si desea eliminar el dato ?",
  seleccionarEmpresa: "Seleccionar Empresa",
  confirmarAnulacion:
    "Si desea continuar con el proceso de anulación presione ANULAR, caso contrario presione CANCELAR.",
  confirmarDesanulacion:
    "Si desea continuar con el proceso de Desanulación presione DESANULACIÓN, caso contrario presione CANCELAR.",
};

export const pageSizeOptions: number[] = [1, 5, 10, 25, 100];

export const dateFormatPerzonalized = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

export const modules = {
  empresa: { name: "empresa", title: "EMPRESA", path: "erp/empresa" },
  proyecto: { name: "proyecto", title: "PROYECTO", path: "erp/proyecto" },
  usuarioEmpresa: {
    name: "usuarioEmpresa",
    title: "USUARIO",
    path: "erp/usuarioEmpresa",
  },
  tipoMejora: {
    name: "tipoMejora",
    title: "TIPO DE MEJORA",
    path: "eficienciaEnergetica/tipoMejora",
  },
  propuestasMejora: {
    name: "propuestasMejora",
    title: "PROPUESTA MEJORA",
    path: "eficienciaEnergetica/propuestasMejora",
  },
  recurso: {
    name: "recurso",
    title: "RECURSO",
    path: "eficienciaEnergetica/recurso",
  },
  plantaIndustrial: {
    name: "plantaIndustrial",
    title: "PLANTA INDUSTRIAL",
    path: "eficienciaEnergetica/plantaIndustrial",
  },
  registroAhorro: {
    name: "registroAhorro",
    title: "REGISTRO AHORROS",
    path: "eficienciaEnergetica/registroAhorro",
  },
  consolidado: {
    name: "consolidado",
    title: "REPORTE CONSOLIDADO",
    path: "eficienciaEnergetica/consolidado",
  },
  reportes: {
    name: "reportes",
    title: "REPORTES",
    path: "eficienciaEnergetica/reportes",
  },
  registroUsuarioProyecto: {
    name: "registroUsuarioProyecto",
    title: "REGISTRO HORAS DE TRABAJO",
    path: "consultor/registroUsuarioProyecto",
  },
  registroAsignacionProyecto: {
    name: "registroAsignacionProyecto",
    title: "REGISTRO DE HORAS DE PROYECTOS",
    path: "consultor/registroAsignacionProyecto",
  },

  graficaProyecto: {
    name: "graficaProyecto",
    title: "Graficas De Proyecto",
    path: "erp/proyectoGraficas",
  },
  consultorReporte: {
    name: "consultorReporte",
    title: "REPORTE HORAS",
    path: "consultor/consultorReporte",
  },
  consolidadoConsultorReporte: {
    name: "consolidadoConsultorReporte",
    title: "REPORTE CONSILIDADO CONSULTOR",
    path: "consultor/consolidadoConsultorReporte",
  },
  empresaEvaluacion: {
    name: "empresaEvaluacion",
    title: "EVALUACIÓN EMPRESA",
    path: "saras/empresaEvaluacion",
  },
  revisar: { name: "respuestas", title: "REVISAR ENCUESTAS" },
  capacitaciones: {
    name: "capacitaciones",
    title: "CAPACITACIONES",
    path: "capacitacion/capacitaciones",
  },
  preguntas: {
    name: "preguntas",
    title: "PREGUNTAS",
    path: "capacitacion/preguntas",
  },
  respuesta: { name: "respuestas", title: "REPORTE CONSULTOR ENCUESTAS" },
  cuentaBanco: {
    name: "cuentaBanco",
    title: "CUENTAS",
    path: "erp/cuentaBanco",
  },
  ingresoEgreso: {
    name: "ingresoEgreso",
    title: "TRANSACCIONES",
    path: "erp/ingresoEgreso",
  },
  transaccion: {
    name: "transaccion",
    title: "TRANSACCIÓN BANCARIA",
    path: "erp/transaccion",
  },
  cierreMes: { name: "cierreMes", title: "CIERRE MES", path: "erp/cierreMes" },
  rubrosIe: { name: "rubrosIe", title: "RUBRO", path: "erp/rubrosIe" },
  comprobante: {
    name: "comprobante",
    title: "COMPROBANTES",
    path: "erp/comprobante",
  },
  facturasCompras: {
    name: "facturasCompras",
    title: "FACTURA COMPRA",
    path: "erp/facturasCompras",
  },
  facturas: { name: "facturas", title: "FACTURAS", path: "erp/facturas" },
  clienteProveedor: {
    name: "clienteProveedor",
    title: "CLIENTE-PROVEEDOR",
    path: "erp/clienteProveedor",
  },
  datosIngresoEgreso: {
    name: "datosIngresoEgreso",
    title: "DATOS CARGOS",
    path: "erp/datosIngresoEgreso",
  },
  retenciones: {
    name: "retenciones",
    title: "RETENCIÓN",
    path: "erp/retenciones",
  },
  estadoCuenta: {
    name: "estadoCuenta",
    title: "ESTADO CUENTA",
    path: "erp/estadoCuenta",
  },
  cuentasReport: {
    name: "cuentasReport",
    title: "REPORTE COMPROBANTES CUENTAS POR COBRAR/PAGAR",
    path: "erp/cuentasReport",
  },
  facturasVentas: {
    name: "facturasVentas",
    title: "FACTURA VENTA",
    path: "erp/facturasVentas",
  },
  pagosProyecto: {
    name: "pagosProyecto",
    title: "PAGOS/HITOS DEL PROYECTO",
    path: "erp/pagosProyecto",
  },
  detalleReport: {
    name: "detalleReport",
    title: "DETALLE COMPROBANTES",
    path: "erp/detalleReport",
  },
  detalleFactura: {
    name: "detalleFactura",
    title: "DETALLE FACTURAS",
    path: "erp/detalleFactura",
  },
  facturaComprobanteConsolidado: {
    name: "facturaComprobanteConsolidado",
    title: "REPORTE CONSOLIDADO FACTURAS Y COMPROBANTES",
    path: "erp/facturaComprobanteConsolidado",
  },
  contratoPagos: {
    name: "contratoPagos",
    title: "REPORTE PAGOS PROYECTO",
    path: "erp/contratoPagos",
  },
  estadoCuentaProyecto: {
    name: "estadoCuentaProyecto",
    title: "REPORTE PROYECTO ESTADO CUENTA",
    path: "erp/estadoCuentaProyecto",
  },
  planCuenta: {
    name: "planCuenta",
    title: "PLAN CUENTAS",
    path: "erp/planCuenta",
  },
  cuentasFacturas: {
    name: "cuentasFacturas",
    title: "REPORTE FACTURAS CUENTAS POR COBRAR/PAGAR",
    path: "erp/cuentasFacturas",
  },
  mapa: { name: "mapa", title: "MAPA", path: "saras/mapa" },
  reportesPeriodos: {
    name: "reportesPeriodos",
    title: "REPORTE INGRESOS - PAGOS",
    path: "erp/reportesPeriodos",
  },
  ordenTrabajo: {
    name: "ordenTrabajo",
    title: "ORDEN DE TRABAJO",
    path: "consultor/ordenTrabajo",
  },
  valorHora: {
    name: "valorHora",
    title: "ESCALA VALOR-HORA",
    path: "consultor/valorHora",
  },
  valorHoraConsultor: {
    name: "valorHoraConsultor",
    title: "VALOR HORA CONSULTOR",
    path: "consultor/valorHoraConsultor",
  },
  rubroPlanCuenta: {
    name: "rubroPlanCuenta",
    title: "RUBRO - PLAN DE CUENTAS",
    path: "erp/rubroPlanCuenta",
  },
  pagosPendientes: {
    name: "pagosPendientes",
    title: "REPORTE PAGOS PENDIENTES",
    path: "erp/pagosPendientes",
  },
  presupuesto: {
    name: "presupuesto",
    title: "PRESUPUESTOS",
    path: "erp/presupuesto",
  },
  seguimiento: {
    name: "seguimiento",
    title: "SEGUIMIENTO",
    path: "erp/seguimiento",
  },
  colaborador: {
    name: "colaborador",
    title: "INFO CONSULTOR",
    path: "consultor/colaborador",
  },
  titulos: {
    name: "colaboradorEdu",
    title: "TITULOS",
    path: "consultor/titulos",
  },
  experiencia: {
    name: "colaboradorExp",
    title: "EXPERIENCIAS",
    path: "consultor/experiencia",
  },
  cursos: { name: "colaboradorCur", title: "CURSOS", path: "consultor/cursos" },
  idiomas: {
    name: "colaboradorIdm",
    title: "IDIOMAS",
    path: "consultor/idiomas",
  },
  //Biblioteca
  biblioteca: {
    name: "biblioteca",
    title: "BIBLIOTECA",
    path: "miscelaneos/biblioteca",
  },

  mapas: {
    name: "mapas",
    title: "MAPAS",
    path: "miscelaneos/mapas",
  },
};
