<div class="container-fluid crud-button" >
 <div class="row crud-search" >
    <ngx-crud-title></ngx-crud-title>
    <span fxFlex></span>
    <ngx-crud-buttom-add></ngx-crud-buttom-add>

  </div>
  <div class="row search">
    <div class="col-12 col-md-12 p-0 pr-md-12">
      <ngx-crud-search></ngx-crud-search>
    </div>
    
  </div>
</div>
   <ng-content></ng-content>
   