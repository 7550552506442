import {Component, OnDestroy, OnInit } from '@angular/core';
@Component({
  selector: 'crud-no-button',
  templateUrl: './crud-no-button.component.html',
  styleUrls: ['./crud-no-button.component.scss']
})
export class CrudNoButtonComponent implements OnInit, OnDestroy {
  constructor(  ) { }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
  }
}
