
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Botton_action, modules } from 'app/@components/confi/confi';
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';
import * as indexActions from 'app/@components/general/ngrx/index/index.actions'
import { validaCedula } from 'app/@components/validators/validaCedula';
import { EMAIL_PATTERN } from 'app/@auth/components';
import * as generalActions from 'app/@components/general/ngrx/general.actions';
import { Empresa } from 'app/@components/models/Empresa';
@Component({
  selector: 'clienteProveedorPopupAddUpd',
  templateUrl: 'clienteProveedorPopupAddUpd.component.html',
  styleUrls: ['clienteProveedorPopupAddUpd.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClienteProveedorPopupAddUpdComponent implements OnInit, OnDestroy {
  public itemForm: FormGroup;
  Botton_action = Botton_action
  popupId = uuid.v4();
  empresa:Empresa
  itemEmpresaSub: Subscription = new Subscription();
  modules=modules
  dataFormSub: Subscription = new Subscription();
  dataForm: any;
  uuidResponseSub: Subscription = new Subscription();
  objectSub: Subscription = new Subscription();
  id: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ClienteProveedorPopupAddUpdComponent>,
    private store: Store<any>,
    private fb: FormBuilder
  ) 
  { 
  }
  ngOnInit() {
    this.id = this.data?.id
    this.empresa=this.data?.empresa
    this.buildItemForm();
    //consulta los datos para el formulario
    this.store.dispatch(generalActions.GetDataFormAction({ id: this.data.id, id1: this.empresa.fS07Id, module: this.modules.clienteProveedor.name, popupId: this.popupId }));
  //    this.store.dispatch(generalActions.GetDataFormAction({ id: this.data.id, module: this.modules.clienteProveedor.name, popupId: this.popupId, actionResult: undefined }));
     this.dataFormSub = this.store.select((state) => state?.general?.item[this.popupId]?.dataForm)
     .subscribe(dataForm => {
      if (dataForm) {
        if (dataForm.data) 
        {
          
         this.itemForm.patchValue(dataForm.data)
       }  
      }
    })
    //close de popup
    this.uuidResponseSub = this.store.select((state) => state?.general?.item[this.popupId]?.uuid)
      .subscribe(uuid => {
        if (uuid) {
          this.close();
        }
      });
  }
  ngOnDestroy(): void {
    this.uuidResponseSub.unsubscribe();
    this.objectSub.unsubscribe();
    this.itemEmpresaSub.unsubscribe();
   // this.store.dispatch(indexActions.ClearStorageAction({ popupId: this.popupId }));
  }


  get fS07Id() { return this.itemForm.get('fS07Id'); }
  get fP01RazonSocial() { return this.itemForm.get('fP01RazonSocial'); }
 // get fP01Id() { return this.itemForm.get('fP01Id'); }
  get fP01Direccion() { return this.itemForm.get('fP01Direccion'); }
  get fP01Telefono() { return this.itemForm.get('fP01Telefono'); }
  get fP01CorreoElectronico() { return this.itemForm.get('fP01CorreoElectronico'); }
  get fP01Ruc() { return this.itemForm.get('fP01Ru'); }

  buildItemForm() {
    this.itemForm = this.fb.group({
      fP01Id: [this.id],
      fS07Id: [ this.empresa.fS07Id],
      fP01RazonSocial: [ '', Validators.required],
      fP01Direccion: [ '', Validators.required],
      fP01Telefono: [ '', Validators.required],
      fP01CorreoElectronico: [ '', [Validators.pattern(EMAIL_PATTERN)]],
      fP01Ruc: [ '', [Validators.required]],
      fP01Tipo: ['N', [Validators.required]],
      fP01Cliente: [false],
      fP01Proveedor: [false],
      fP01Activo: [ true],
      fP01ProveedorEvaluado: [ false]
    })
  }
  close(): void {
    this.dialogRef.close();
  }
  save() {
    
    if (this.itemForm.invalid) {
      this.itemForm.markAllAsTouched();
      return;
    }
    
    if (this.itemForm.value && this.id == 0) {
      this.store.dispatch(indexActions.PostAction({ data: this.itemForm.value, module: this.modules.clienteProveedor.name, formData:false, popupId: this.popupId }));
    } else {
      this.store.dispatch(indexActions.PutAction({ id: this.data.id, data: this.itemForm.value,  module: this.modules.clienteProveedor.name, formData:false, popupId: this.popupId }));
    }
  }

}
