import * as indexActions from './index.actions';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, tap } from 'rxjs/operators';
import { HttpService } from '../../services/http.service';
import { RespuestaService } from 'app/@components/respuesta.service';
import {ShowToastService} from '../../../showToast.service'
import { Store } from '@ngrx/store';
import * as uuid from 'uuid';
import * as generalActions from '../general.actions'
@Injectable()
export class IndexEffects {

  constructor(
    private actions$: Actions,
    private httpService: HttpService,
    private respuestaService: RespuestaService,
      private showToastService: ShowToastService,
    private store: Store<any>,
  ) { }
  GetItemsAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(indexActions.GetItemsAction),
      switchMap(() =>
        this.httpService.getItems().pipe(
          map((res: any) => indexActions.SetItemsAction({ items: res.data.elementos?res.data.elementos:res.data, count: res.data.cantidadElementos }))
        ))));
//muestra toda l informacion para descargar
GetAllAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(indexActions.GetAllAction),
      switchMap((action) =>
        this.httpService.getItemsAll(action.quantity).pipe(
          map((res: any) => indexActions.SetAllAction({ items:  res.data.elementos?res.data.elementos:res.data }))
        ))));

  //busca por la canitidad de datos
  NumberPageQuantityAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(indexActions.NumberPageQuantityAction),
     // tap(data => console.log('datos', data)),
      switchMap(() =>
        this.httpService.getItems().pipe(
          map((res: any) => indexActions.SetItemsAction({ items: res.data.elementos, count: res.data.cantidadElementos }))
        ))));

  ChangeOrderByAndOrderTypeAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(indexActions.ChangeOrderByAndOrderTypeAction),
      switchMap(() =>
        this.httpService.getItems().pipe(
          map((res: any) => indexActions.SetItemsAction({ items: res.data.elementos, count: res.data.cantidadElementos }))
        ))));

  SearchAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(indexActions.SearchAction),
      switchMap(() =>
        this.httpService.getItems().pipe(
          map((res: any) => indexActions.SetItemsAction({ items: res.data.elementos, count: res.data.cantidadElementos }))
        ))));
  
//buscamos las empresas
GetByIdListAction$ = createEffect(() =>
this.actions$.pipe(
  ofType(indexActions.GetByIdListAction),
 // tap(data=>console.log('datos', data)),
  switchMap((action) =>
    this.httpService.GetByIdList(action.id, action.id1, action.module, action.actionResult).pipe(
      map((res: any) =>{
        if(action.id)
        {
          //this.store.dispatch(itemActions.GetObjectAction({ id: action.id, module: action.module, popupId: action.popupId }));
        }
       return indexActions.SetByIdListAction({ items: res.data, popupId:action.popupId });
      }) 
))));

GetListById$ = createEffect(() =>
this.actions$.pipe(
  ofType(indexActions.GetListById),
 // tap(data=>console.log('datos', data)),
  switchMap((action) =>
    this.httpService.GetListById(action.id, action.module, action.actionResult).pipe(
      map((res: any) =>{
        if(action.id)
        {
          //this.store.dispatch(itemActions.GetObjectAction({ id: action.id, module: action.module, popupId: action.popupId }));
        }
       return indexActions.SetListById({ items: res.data, popupId:action.popupId });
      }) 
))));

PostAction$ = createEffect(() =>
this.actions$.pipe(
  ofType(indexActions.PostAction),
  switchMap((action) =>
    this.httpService.postItem(action.data, action.module, action.formData).pipe(
      map((res: any) => { 
        return generalActions.PostPutDeleteSuccessAction({ uuid: uuid.v4(), popupId: action.popupId, showNotification: action.showNotification, module: action.module, reloadIndex: action.noReloadIndex });
      }) 
))));
//actualzia datos
PutAction$ = createEffect(() =>
this.actions$.pipe(
  ofType(indexActions.PutAction),
  switchMap((action) =>
    this.httpService.putItem(action.id, action.data, action.module, action.formData).pipe(
      map((res: any) => {
        if (action.noShowToastr) {
          if (action.noReloadIndex) {
            return generalActions.PostPutDeleteSuccessAction({ uuid: uuid.v4(), popupId: action.popupId, module: action.module, showNotification: false, reloadIndex: false });
          } else {
            return generalActions.PostPutDeleteSuccessAction({ uuid: uuid.v4(), popupId: action.popupId, module: action.module, showNotification: false });
          }
        } else {
          if (action.noReloadIndex) {
            return generalActions.PostPutDeleteSuccessAction({ uuid: uuid.v4(), popupId: action.popupId, module: action.module, reloadIndex: false });
          } else {
            return generalActions.PostPutDeleteSuccessAction({ uuid: uuid.v4(), popupId: action.popupId, module: action.module });
          }
        }
      })
))));

//tipo de mensaje
PostPutDeleteSuccessAction$ = createEffect(() =>
        this.actions$.pipe(
          ofType(generalActions.PostPutDeleteSuccessAction),
          tap((action) => {
             if (action.tipoMensaje=='2')
            {
              let respuesta=this.respuestaService.successUpdate();
              this.showToastService.showToast('success', respuesta.message, respuesta.detalle);
            }
            else if (action.tipoMensaje=='3')
            {
              let respuesta=this.respuestaService.successDelete();
              this.showToastService.showToast('success', respuesta.message, respuesta.detalle);
            }
            else if (action.tipoMensaje=='4')
            {
              let respuesta=this.respuestaService.successAnular();
              this.showToastService.showToast('success', respuesta.message, respuesta.detalle);
            }
            else
            {
              let respuesta=this.respuestaService.successInsert();
              this.showToastService.showToast('success', respuesta.message, respuesta.detalle);
            }
            if (action.reloadIndex != false) {
              this.store.dispatch(indexActions.GetItemsAction());
            }
          })
        ), { dispatch: false });

//DEVULEVE LA CONSULTA POR ID
GetByIdAction$ = createEffect(() =>
this.actions$.pipe(
  ofType(indexActions.GetByIdAction),
  switchMap((action) =>
    this.httpService.GetByIdList(action.id, action.id1, action.module, action.actionResult).pipe(
      map((res: any) => indexActions.SetByIdAction({ object: res.data, popupId: action.popupId }))
))));

GetCiiuPaginationAction$ = createEffect(() =>
this.actions$.pipe(
  ofType(indexActions.GetCiiuPaginationAction),
  switchMap((action) =>
    this.httpService.GetItemPagination(action.id, action.module, action.actionResult, action.quantity, action.page, action.searchText).pipe(
      map((res: any) => indexActions.SetCiiuPaginationAction({  listCiiu: res.data, popupId: action.popupId, searcText:action.searchText }))
))));
GetProyectoPaginationAction$ = createEffect(() =>
this.actions$.pipe(
  ofType(indexActions.GetProyectoPaginationAction),
  switchMap((action) =>
    this.httpService.GetItemPagination(action.id, action.module, action.actionResult, action.quantity, action.page, action.searchText).pipe(
      map((res: any) => indexActions.SetProyectoPaginationAction({  proyecto: res.data, popupId: action.popupId, searcText:action.searchText }))
))));

GetProvinciasByIdPaisAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(indexActions.GetProvinciasByIdPaisAction),
      switchMap((action) =>
        this.httpService.GetByIdList(action.id, action.id1, action.module, action.actionResult).pipe(
          map((res: any) => indexActions.SetProvinciasByIdPaisAction({ provincias: res.data, popupId: action.popupId }))
  ))));


GetCiudadesByIdProvinciaAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(indexActions.GetCiudadesByIdProvinciaAction),
      switchMap((action) =>
        this.httpService.GetByIdList(action.id, action.id1, action.module, action.actionResult).pipe(
          map((res: any) => indexActions.SetCiudadesByIdProvinciaAction({ ciudades: res.data, popupId: action.popupId }))
  ))));
  GetpreguntaAction$ = createEffect(() =>
  this.actions$.pipe(
    ofType(indexActions.GetpreguntaAction),
    switchMap((action) =>
      this.httpService.GetByIdList(action.id, action.id1, action.module, action.actionResult).pipe(
        map((res: any) => indexActions.SetByIdAction({ object: res.data, popupId: action.popupId }))
))));
  

        }