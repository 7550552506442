<ngx-crud-escifica-empresa>
  <!--Sección de la tabla RegistroUsuarioProyecto-->
  <div class="crud-table-container">
    <table
      mat-table
      [dataSource]="dataSource"
      multiTemplateDataRows
      matSort
      (matSortChange)="sortData($event)"
      class="ngx-crud mat-elevation-z8"
    >
      <!--Proyecto-->
      <ng-container matColumnDef="Dia">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Dia</th>
        <td mat-cell *matCellDef="let diaFecha">
          {{ diaFecha.dia }}
        </td>
      </ng-container>
      <!--Fecha de Inicio (Fecha)-->
      <ng-container matColumnDef="fT08Inicio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
        <td mat-cell *matCellDef="let diaFecha">
          {{ diaFecha.fecha | date : "yyyy/MM/dd" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Usuario">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
        <td mat-cell *matCellDef="let diaFecha">
          {{ diaFecha.usuario }}
        </td>
      </ng-container>
      <!--Duración-->
      <ng-container matColumnDef="horaDuracion">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          style="padding-left: 0.5%"
        >
          Duración
        </th>
        <td mat-cell *matCellDef="let element" style="padding-left: 1%">
          {{ element.horaDuracion | formatTimer : 5 }}
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
          style="background: #303030 !important"
        >
          <div
            class="example-element-detail"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
          >
            <table
              mat-table
              [dataSource]="element?.registroUsuarioProyecto"
              class="mat-elevation-z8"
              width="100%"
            >
              <ng-container matColumnDef="horaDuracion">
                <th mat-header-cell *matHeaderCellDef>Duracion</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.horaDuracion }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Acciones">
                <th mat-header-cell *matHeaderCellDef>Acciones</th>
                <td mat-cell *matCellDef="let row">
                  <div class="buttons-row">
                    <button
                      *ngIf="!lectura"
                      type="button"
                      size="small"
                      nbTooltip="{{ Botton_action.Eliminar.descripcion }}"
                      nbButton
                      matRipple
                      (click)="deletePopUp(row)"
                    >
                      <nb-icon class="action-icon" icon="trash-2"></nb-icon>
                    </button>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
            </table>
          </div>
        </td>
      </ng-container>

      <!--Menú de Acciones (Editar,Detalle,Eliminar)-->
      <ng-container matColumnDef="Acciones">
        <th mat-header-cell *matHeaderCellDef>Acciones</th>
        <td mat-cell *matCellDef="let row">
          <div class="buttons-row">
            <button
              type="button"
              size="small"
              nbTooltip="{{ Botton_action.Editar.descripcion }}"
              nbButton
              matRipple
              class="mr-1"
              (click)="insertEditDataPopup(row, 'Editar')"
            >
              <nb-icon class="action-icon" icon="edit"></nb-icon>
            </button>

            <button
              type="button"
              size="small"
              nbTooltip="{{ Botton_action.Eliminar.descripcion }}"
              nbButton
              matRipple
              (click)="deletePopUp(row)"
            >
              <nb-icon class="action-icon" icon="trash-2"></nb-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <!--Sección de visualización de datos-->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="example-detail-row"
      ></tr>
    </table>
  </div>
  <!--Sección de paginación-->
</ngx-crud-escifica-empresa>
