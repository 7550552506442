
<div class="header-container"

>
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
   
    <img class="logo" width="100%" src="../../../../assets/images/LogoBlancoHorizontal.png" alt="" class="mb-05">
  </div>
  <!--
  <mat-form-field appearance="fill" style="margin-left: 60px; margin-top: 20px;">
    <mat-select  [(value)]="selected" (selectionChange)="SetIdEmpresa($event.value)" placeholder="Seleccionar Empresas">
      <mat-option *ngFor="let item of items" [value]="item.fS07Id"> {{ item.fS07RazonSocial }}</mat-option>
    </mat-select>
    </mat-form-field>
    -->
</div>

<div class="header-container" >
  <nb-actions size="small">
    <nb-action class="user-action"  *nbIsGranted="['view', 'current-user']">
      <div class="img-button" 
      [nbContextMenu]="userMenu"
                >
        {{usernName1}}
      </div>
    </nb-action>
  </nb-actions>
</div>
