
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NB_AUTH_OPTIONS } from '@nebular/auth';
import { AuthService } from 'app/@auth/auth.service';
import { getDeepFromObject } from '../../helpers';
import { EMAIL_PATTERN } from '../constants';

@Component({
  selector: 'ngx-request-password-page',
  styleUrls: ['./request-password.component.scss'],
  templateUrl: './request-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxRequestPasswordComponent implements OnInit {
  redirectDelay: number = this.getConfigValue('forms.requestPassword.redirectDelay');
  showMessages: any = this.getConfigValue('forms.requestPassword.showMessages');
  strategy: string = this.getConfigValue('forms.requestPassword.strategy');
  isEmailRequired: boolean = this.getConfigValue('forms.validation.email.required');
  errors= false;
  success= false;
  loading = false;
  submitted = false;
  requestPasswordForm: FormGroup;

  constructor(protected service: AuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              protected cd: ChangeDetectorRef,
              protected fb: FormBuilder,
              protected router: Router) { }

  get email() { return this.requestPasswordForm.get('email'); }

  ngOnInit(): void {
    const passwordValidators = [
      Validators.pattern(EMAIL_PATTERN),
    ];
    this.isEmailRequired && passwordValidators.push(Validators.required);

    this.requestPasswordForm = this.fb.group({
      email: this.fb.control('', [...passwordValidators]),
    });
  }

  requestPass(): void
   {
    this.submitted = true;
    this.loading = true;
    this.service.ResetPassword(this.requestPasswordForm.value.email).subscribe(data => {
      
      this.submitted = false;
      this.loading = false;
      if (data.success)
       {
        this.errors = false;
        this.success=true;
        this.requestPasswordForm.reset()
      } 
      else {
        this.errors = true;
        this.success=false;
      }
      this.cd.detectChanges();
    } ,(err)=>{
      
      this.submitted = false;
      this.loading = false;
      this.errors = true;
      this.success=false;
      
      this.cd.detectChanges();
    });
  }
/*login()
{
  this.router.navigate(['/auth']);
}*/
  


  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }
}
