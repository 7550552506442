<div class="altoTituloPopop">
  <span class="titulo"> {{ modules.registroAsignacionProyecto.title }}</span>
  <span fxFlex></span>
  <button
    type="button"
    mat-icon-button
    class="mt--10"
    (click)="dialogRef.close()"
  >
    <mat-icon> close </mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography dialog-content">
  <div class="container">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <!-- Sección de Fecha -->
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Fecha</h5>
              <mat-form-field class="input-example">
                <mat-label>Selecciona una fecha</mat-label>
                <input
                  matInput
                  formControlName="fT08Inicio"
                  [matDatepicker]="picker"
                  [matDatepickerFilter]="dateFilter"
                  required
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Div para agrupar el input type="time" y el textarea -->
      <div class="hora-y-mensaje">
        <div class="casilla">Porcentaje</div>
        <div class="casilla">Proyecto</div>
        <div class="casilla">Hora</div>
        <div class="casilla">Comentario</div>
      </div>
      <div *ngFor="let diaYfecha of diaYFecha" class="contenedor">
        <input
          class="oculto"
          [value]="diaYfecha.f_T02_Id"
          [disabled]="!diaYfecha.f_T02_Id"
        />

        <!-- Div para agrupar el input type="time" y el textarea -->
        <div class="hora-y-mensaje">
          <input
            type="text"
            readonly
            style="border: 1px solid transparent"
            [value]="formatPercentage(diaYfecha.monto)"
            [ngStyle]="{
              color: getColor(diaYfecha.monto)
            }"
          />

          <input
            type="text"
            class="input-medio"
            readonly
            style="border: 1px solid transparent"
            [id]="diaYfecha.f_T02_Id || diaYfecha.fT02Id"
            [value]="diaYfecha.f_T02_Nombre || diaYfecha.fT02Nombre"
          />

          <input
            type="time"
            id="hora"
            name="hora"
            class="input-medioTime"
            [value]="diaYfecha.f_T08_Duracion"
            (input)="diaYfecha.f_T08_Duracion = $event.target.value"
          />

          <textarea
            id="mensaje"
            name="mensaje"
            class="input-medio"
            placeholder="Escribe aquí tu mensaje..."
            (input)="diaYfecha.f_T08_Detalle = $event.target.value"
            >{{ diaYfecha.f_T08_Detalle }}</textarea
          >
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>

<!--Sección Guardar-Cancelar-->
<mat-dialog-actions align="end">
  <button
    class="mr-1"
    type="button"
    size="small"
    matRipple
    nbButton
    status="disabled"
    (click)="dialogRef.close(false)"
  >
    {{ Botton_action.Cancelar.nombre }}
  </button>
  <button nbButton matRipple size="small" (click)="onSubmit()" status="primary">
    {{ Botton_action.Guardar.nombre }}
  </button>
</mat-dialog-actions>
<!-- Campo Proyecto -->

<!-- 
      <div class="col-md-12 col-lg-6 col-xxxl-6">
        <div class="form-group">
          <mat-form-field class="input-example">
            <mat-label class="label">Proyecto</mat-label>
            <mat-select
              formControlName="fT02Id"
              placeholder="Seleccione"
              required
            >
              <mat-option>
                <ngx-mat-select-search
                  [formControl]="proyectoFilterCtrl"
                  [placeholderLabel]="'Buscar...'"
                  [noEntriesFoundLabel]="'No se encontraron resultados'"
                ></ngx-mat-select-search>
              </mat-option>
              <mat-option
                *ngFor="let item of proyectoFiltered | async"
                [value]="item?.fT02Id"
              >
                {{ item?.fT02Nombre }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <ngx-validation-message-crud
            label="Proyecto"
            [showRequired]="fT02Id?.errors?.required && fT02Id.touched"
          >
          </ngx-validation-message-crud>
        </div>
      </div>
   
      <div class="col-md-12 col-lg-12 col-xxxl-12">
        <div
          class="table-container mat-elevation-z8 mx-auto"
          style="max-width: 60%"
        >
          <table
            class="tablaDetalle material-table"
            formArrayName="registroUsuarioProyecto"
          >
    
            <thead>
              <tr>
                <th class="label" style="text-align: center">Duración</th>
                <th class="label">
                  <a
                    class="pt-8"
                    matTooltip="{{ Botton_action.Crear.descripcion }}"
                    mat-icon-button
                    (click)="addItem(item)"
                  >
                    <mat-icon>add_circle_outline</mat-icon>
                  </a>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                *ngFor="
                  let registroUsuarioProyecto of itemArray.controls;
                  let i = index
                "
                [formGroup]="registroUsuarioProyecto"
              >
                <td class="p-8" data-label=" Duracion " class="center-cell">
                  <mat-form-field class="">
                    <input
                      matInput
                      type="time"
                      formControlName="horaDuracion"
                      required
                    />
                  </mat-form-field>
                  <ngx-validation-message-crud
                    label="Duracion"
                    [showRequired]="
                      registroUsuarioProyecto.controls.horaDuracion.errors
                        ?.required &&
                      registroUsuarioProyecto.controls.horaDuracion.touched
                    "
                    [timeInvalid]="
                      registroUsuarioProyecto.controls.horaDuracion.errors
                        ?.timeInvalid
                    "
                  >
                  </ngx-validation-message-crud>
                </td>
                <td data-label="Acciones">
                  <a
                    class="pt-8"
                    matTooltip="{{ Botton_action.Eliminar.descripcion }}"
                    mat-icon-button
                    (click)="DeleteaItemArray(registroUsuarioProyecto.value, i)"
                  >
                    <mat-icon>delete</mat-icon>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
         
          <div
            *ngIf="
              itemForm
                .get('registroUsuarioProyecto')
                .hasError('totalHoursExceeded')
            "
            class="error-message"
            style="text-align: center; color: red"
          >
           
          </div>
        </div>
      </div>
-->
