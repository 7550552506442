
import { Injectable } from '@angular/core';
import { Respuesta } from '../@components/models/Respuesta';
@Injectable({
     providedIn: 'root'
})
export class RespuestaService {
     respuesta: Respuesta = {}
     constructor() {
     }
     successInsert() {

          this.respuesta.success = true;
          this.respuesta.message = "Éxito";
          this.respuesta.detalle = "Datos registrados correctamente";
          return this.respuesta;

     }
     successAnular() {

          this.respuesta.success = true;
          this.respuesta.message = "Éxito";
          this.respuesta.detalle = "Se anulado el  registro correctamente";
          return this.respuesta;

     }
     successUpdate() {

          this.respuesta.success = true;
          this.respuesta.message = "Éxito";
          this.respuesta.detalle = "Datos actualizados correctamente";
          return this.respuesta;

     }

     successDelete() {

          this.respuesta.success = true;
          this.respuesta.message = "Dato eliminado";
          this.respuesta.detalle = "";
          return this.respuesta;

     }

     ModelIvalid() {

          this.respuesta.success = false;
          this.respuesta.message = "Error";
          this.respuesta.detalle = "Datos del modelo inválido";
          return this.respuesta;
     }
     ExistsData() {

          this.respuesta.success = false;
          this.respuesta.message = "Datos ya existe";
          this.respuesta.detalle = "Esta información ya existe";
          return this.respuesta;
     }
     Error() {
          this.respuesta.success = false;
          this.respuesta.message = "Error";
          this.respuesta.detalle = "Comuniquese con el administrador del sistema";
          return this.respuesta;
     }    

     IdInalid() {
          this.respuesta.success = false;
          this.respuesta.message = "Error";
          this.respuesta.detalle = "el Id es inválido";
          return this.respuesta;
     }

     RelatedData() {
          this.respuesta.success = false;
          this.respuesta.message = "Advertencia";
          this.respuesta.detalle = "No se puede eliminar este dato esta relacionado";
          return this.respuesta;
     }

     SinDatosAplicacion() {
          this.respuesta.success = false;
          this.respuesta.message = "Info";
          this.respuesta.detalle = "No existe datos para la aplicación seleccionada";
          return this.respuesta;
     }

     SinDatosGeneral() {
          this.respuesta.success = false;
          this.respuesta.message = "Info";
          this.respuesta.detalle = "No existe datos para seleccionar";
          return this.respuesta;
     }

     SeleccionarDatos() {
          this.respuesta.success = false;
          this.respuesta.message = "Info";
          this.respuesta.detalle = "Seleccione un dato para visualizar la información";
          return this.respuesta;
     }

     downloadSuccess() {
          this.respuesta.success = true;
          this.respuesta.message = "Éxito";
          this.respuesta.detalle = "Datos descargados correctamente";
          return this.respuesta;
     }

     limitTime() {
          this.respuesta.success = true;
          this.respuesta.message = "Info";
          this.respuesta.detalle = "El Limite de Registro Diario se cumplio";
          return this.respuesta;
     }

     downloadNoData() {
          this.respuesta.success = false;
          this.respuesta.message = "Info";
          this.respuesta.detalle = "No existe datos para descargar";
          return this.respuesta;
     }

     withOutmenu(usuario: string) {
          this.respuesta.success = false;
          this.respuesta.message = "Info";
          this.respuesta.detalle = "Hola " + usuario + " No tiene menu asignado, por favor comuniquese con el administrador del sistema";
          return this.respuesta;
     }

     itemEmpresa() {
          this.respuesta.success = false;
          this.respuesta.message = "Info";
          this.respuesta.detalle = "Debe seleccionar empresa para continuar";
          return this.respuesta;
     }

     itemEmpresaBanco() {
          this.respuesta.success = false;
          this.respuesta.message = "Info";
          this.respuesta.detalle = "Debe seleccionar la empresa y el banco para continuar";
          return this.respuesta;
     }

     itemEmpresaEstado() {
          this.respuesta.success = false;
          this.respuesta.message = "Info";
          this.respuesta.detalle = "Debe seleccionar la empresa y el estado para continuar";
          return this.respuesta;
     }

     withOutPayment() {
          this.respuesta.success = false;
          this.respuesta.message = "Error";
          this.respuesta.detalle = "No Puede crear la factura, no tiene pagos creados para el proyecto seleccionado";
          return this.respuesta;
     }

     withOutValue() {
          this.respuesta.success = false;
          this.respuesta.message = "Error";
          this.respuesta.detalle = "El valor total debe ser  mayor a > 0";
          return this.respuesta;
     }
}
