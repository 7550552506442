import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UtilService {
  menu:any[]=[]
  menusSub: Subscription = new Subscription();
  
  constructor(
    private store: Store<any>
  ) {
    this.menusSub = this.store.select((state) => state?.auth?.lecturaMenus)
        .subscribe(data => {
          if (data) {   
           this.menu=data
          }
        });
  }
  validateReading(modulo: string):boolean {
    let data = this.menu.find(i => i.link == modulo);
    return data?.aS04Lectura;
  }
}
