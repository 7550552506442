<div class="container-fluid crud-button">
  <div class="row crud-search">
    <ngx-crud-title></ngx-crud-title>
    <span fxFlex></span>
    <ngx-crud-buttom-add></ngx-crud-buttom-add>
  </div>
  <div class="row search">
    <div class="col-12 col-md-6 pr-md-6">
      <mat-form-field class="input-example">
        <mat-label class="label">Seleccionar Empresa </mat-label>
        <mat-select
          [(value)]="selectedEmpresa"
          (selectionChange)="search(selectedEmpresa)"
        >
          <mat-option *ngFor="let empresa of empresas" [value]="empresa">
            {{ empresa.fS07NombreComercial }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-12 col-md-6 pr-md-6">
      <div class="row">
        <div class="col-md-12 col-lg-3 col-xxxl-3">
          <mat-form-field class="input-example" color="accent">
            <mat-label>Fecha Inicio</mat-label>
            <input
              #inputRef
              matInput
              [value]="minDate"
              type="date"
              (input)="onInputChange(inputRef.value)"
              [max]="maxDate"
            />

            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-12 col-lg-3 col-xxxl-3">
          <div class="form-group">
            <mat-form-field class="input-example" color="accent">
              <mat-label>Fecha Final</mat-label>
              <input
                #inputReFinal
                matInput
                [value]="maxDate"
                type="date"
                (input)="onInputChangeFinal(inputReFinal.value)"
                [max]="maxDate"
              />

              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 col-xxxl-3">
          <button
            nbButton
            matRipple
            shape="round"
            size="medium"
            status="primary"
            class="mr-1"
            (click)="buscar()"
          >
            <nb-icon class="action-icon" icon="search-outline"></nb-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-content></ng-content>
