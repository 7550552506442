<div class="altoTituloPopop">
  <span class="titulo"> {{ data?.title }} </span>
  <span fxFlex></span>
  <button
    type="button"
    mat-icon-button
    class="mt--10"
    (click)="dialogRef.close()"
  >
    <mat-icon> close </mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">
        <nb-alert outline="warning">
          <span>{{ titulos.confirmarEliminacion }} </span>
          <div *ngFor="let item of items">
            <b> {{ item?.fecha }}</b>
          </div>
          <br />
          <br />
          ¡Cuidado! Esta acción es irreversible.!
        </nb-alert>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    class="mr-1"
    type="button"
    matRipple
    nbButton
    status="disabled"
    (click)="dialogRef.close(false)"
  >
    {{ Botton_action.Cancelar.nombre }}
  </button>
  <button nbButton matRipple status="primary" (click)="submit()">
    {{ Botton_action.Eliminar.nombre }}
  </button>
</mat-dialog-actions>
