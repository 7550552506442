import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { buscar_por_columna } from 'app/@components/confi/confi';
import { debounceTime } from 'rxjs/operators';
import *as indexActions from '../../ngrx/index/index.actions'


@Component({
  selector: 'ngx-crud-search',
  templateUrl: './crud-search.component.html',
  styleUrls: ['./crud-search.component.scss']
})
export class CrudSearchComponent implements OnInit, OnDestroy {
  buscar_por_columna=buscar_por_columna
  search=new FormControl('',[]);
  constructor(    
    private store: Store<any>,
    ) 
    {
      this.search.valueChanges.pipe(
        debounceTime(350)
      ).subscribe(value=>{
        if (value && value.trim()) {
          this.store.dispatch(indexActions.SearchAction({ search: value }));
        } else {
          this.store.dispatch(indexActions.SearchAction({ search: undefined }));
        }
      })
   }
  ngOnInit(): void {
    
  }
  ngOnDestroy(){
  }
}
