import * as generalActions from './general.actions';
import { createReducer, on, Action } from '@ngrx/store';
import * as indexActions from './index/index.actions';

export interface State {
  modulo: any;
  index: any;
  item: any;
  itemList:any;
  deleteItemArray:any;
  loading: boolean;
  enableDesable:any;
  tipoMensaje:any;
}
export const initialState: State = {
  loading:false,
  modulo: {
    name: undefined,
    title: undefined,
    actionCreate: undefined,
    lectura:undefined,
    existsIdentification:undefined,
    actionResult:undefined,
    tipoMensaje:undefined
  },
  index: {
    items: undefined,
    allItems: undefined,
    itemsSelected: undefined,
    getByIdList: undefined,
    getByIdListEmpresa: undefined,
    itemEmpresa: undefined,
    itemEmpresaPupop: undefined,
    idCliente:undefined,
    count: undefined,
    page: 0,
    quantity: 10,
    search: undefined,
    orderBy: undefined,
    orderType: undefined,
    parameters:undefined
  },
  item: {},
  itemList: {},
  deleteItemArray:undefined,
  enableDesable:false,
  tipoMensaje:undefined
};

const addReducer = createReducer(
  initialState,
  // add
  on(generalActions.ClearStorageAction, (state) => ({ ...initialState, modulo: { name: state?.modulo?.name, title: state?.modulo?.title } })),
  //
  on(indexActions.ClearStorageAction, (state) => ({ ...state, index: initialState.index })),
  
  on(indexActions.ClearStorageAction, (state, { popupId }) => {
    let item = {
      ...state.item
    };
     
    item[popupId] = undefined;
    return ({
      ...state,
      item
    });
  }),

  //titulo del crrud
  on(generalActions.SetTititleCrud, (state, { name, title, actionResult }) => ({ ...state, modulo: { ...state.modulo, name, title, actionResult } })),
   //titulo del crrud
   on(indexActions.SetIdEmpresaAction, (state, { itemEmpresa }) => ({ ...state, index: { ...state.index, itemEmpresa } })),
   //si hay error habilita button
   on(generalActions.SetEnableDesableButton, (state, { enableDesable }) => ({ ...state, enableDesable: enableDesable })),
   on(indexActions.SetItemEmpresaAction, (state, { itemEmpresaPupop }) => ({ ...state, index: { ...state.index, itemEmpresaPupop } })),
   
  //crea un nuevo item
  on(generalActions.SetActionCreateAction, (state, { actionCreate }) => ({  ...state, modulo: { ...state.modulo, actionCreate } })),
  //lectura 
  //on(generalActions.SetLectura, (state, { lectura }) => ({  ...state, modulo: { ...state.modulo, lectura } })),
  on(generalActions.SetPath, (state, { path }) => ({  ...state, modulo: { ...state.modulo, path } })),
  //lista todos los datos
  on(indexActions.GetItemsAction, (state) => ({ ...state })),
  //busca para todos los datos
  on(indexActions.SearchAction, (state, { search }) => ({
    ...state,
    index: {
        ...state.index,
        search,
        page: 0
      }
})),

on(indexActions.SetItemsAction, (state, { items, count }) => ({
  ...state,
  index: {
      ...state.index,
      items,
      //itemsSelected: undefined,
      count
    }
})),

on(indexActions.NumberPageQuantityAction, (state, { page, quantity }) => ({
  ...state,
  index: {
      ...state.index,
      page,
      quantity
    }
})),

on(indexActions.ChangeOrderByAndOrderTypeAction, (state, { orderBy, orderType }) => ({
  ...state,
  index: {
      ...state.index,
      orderBy,
      orderType
    }
})),

on(indexActions.SearchAction, (state, { search }) => ({
  ...state,
  index: {
      ...state.index,
      search,
      page: 0
    }
})),

on(indexActions.GetParametersReportschAction, (state, { parameters }) => ({
  ...state,
  index: {
      ...state.index,
      parameters
    }
})),
on(indexActions.GetParametersId, (state, { id }) => ({
  ...state,
  index: {
      ...state.index,
      id
    }
})),
//lista 
on(indexActions.GetByIdListAction, (state, { id,module, actionResult  }) => ({ ...state })),
on(indexActions.SetByIdListAction, (state, { items, popupId}) => {
  let item = {
    ...state.item
  };
  item[popupId] = {
    ...state.item[popupId],
    items
  };
  return ({
    ...state,
    item
  });
}),

//LISTA BY ID PRINCIPAL
on(indexActions.GetListById, (state, { id,module, actionResult  }) => ({ ...state })),
on(indexActions.SetListById, (state, { items, popupId}) => {
  let item = {
    ...state.item
  };
  item[popupId] = {
    ...state.item[popupId],
    items
  };
  return ({
    ...state,
    item
  });
}),

//lista todos los datos para reportes
on(indexActions.GetAllAction, (state, { quantity }) => ({ ...state })),
on(indexActions.SetAllAction, (state, { items }) => ({
  ...state,
  index: {
      ...state.index,
      allItems: items
    }
})),

//envia los datos metodo Post
on(indexActions.PostAction, (state, { data, module, popupId, formData, showNotification, noReloadIndex }) => ({ ...state })),
//envia los datos metodo Post
on(indexActions.PutAction, (state, { id, data, module, popupId, formData, noReloadIndex, noShowToastr }) => ({ ...state })),
  
on(generalActions.PostPutDeleteSuccessAction, (state, { uuid, popupId, showNotification, reloadIndex, tipoMensaje, cerrarPupop }) => {
  let item = {
    ...state.item
  };

  if (popupId && !cerrarPupop) {
    item[popupId] = {
      ...state.item[popupId],
      uuid,
      tipoMensaje
    };
  }
  else
  {
    item[popupId] = {
      ...state.item[popupId],
      tipoMensaje
    };
  }

  return ({
    ...state,
    item
  });
}),

on(generalActions.SetErrorAction, (state, { error }) => ({ ...state, error })),
//on(generalActions.SetErrorWithOutAction, (state) => ({ ...state })),
//valida identificacion por cliente
on(generalActions.GetExistsAction, (state, { existsIdentification, module, funtion  }) => ({
  ...state,
  modulo:{
    existsIdentification
  }
})),
//retorna valores de existe identificacion
on(generalActions.SetExistsAction, (state, { existsIdentification }) => ({
  ...state,
  modulo: {
    existsIdentification
    }
})),

//MUSTRA LOS DATOS POR ID
on(indexActions.GetByIdAction, (state, { id, module, actionResult, popupId }) => ({ ...state })),
//OBTIENE LOS DATOS POR ID
on(indexActions.SetByIdAction, (state, { object, popupId }) => {
  let item = {
    ...state.item
  }
  item[popupId] = {
    ...state.item[popupId],
    object
  };
  return ({
    ...state,
    item
  });
}),

//eliminar datos
on(generalActions.DeleteItemsAction, (state, { module, ids, popupId }) => ({ ...state })),
//eliminar popup

on(generalActions.SetDeleteDataPopupItemsAction, (state, { uuid }) => ({ ...state, deleteItemArray: { ...state.deleteItemArray, uuid } })),
//reicia datos de un popup
on(generalActions.SetAnularDataPopupItemsAction, (state, { reiniciar }) => ({ ...state, tipoMensaje: { ...state.tipoMensaje, reiniciar } })),

//CARGAR EL SPPINER MIETRAS CARGA LA INFORMACION
on(generalActions.EnableLoadAction, state => ({ ...state, loading: true })),
on(generalActions.DisableLoadAction, state => ({ ...state, loading: false })),

//muestra los datos por el formulario
on(generalActions.GetDataFormAction, (state, { id, module, popupId }) => ({ ...state })),

on(generalActions.SetDataFormAction, (state, { dataForm, popupId }) => {
  let item = {
    ...state.item
  };
  item[popupId] = {
    ...state.item[popupId],
    dataForm,
  };
  return ({
    ...state,
    item
  });
}),
//paginacion CIIU
on(indexActions.GetCiiuPaginationAction, (state, { id, module, popupId, actionResult, quantity, page }) => ({ ...state })),
on(indexActions.SetCiiuPaginationAction, (state, { listCiiu, popupId, searcText }) => {
  let item = {
    ...state.item
  };
  item[popupId] = {
    ...state.item[popupId],
    ciiu:listCiiu
  };
  return ({
    ...state,
    item
  });
 
 
  /* 
  let item = {
    ...state.item
  };
item[popupId] = {
  ...state.item[popupId],
  dataForm: {
    ...state.item[popupId].dataForm,
    ciiu: listCiiu,

  }
};
  return ({
    ...state,
    item
  });*/
}),

//paginacion CIIU
on(indexActions.GetProyectoPaginationAction, (state, { id, module, popupId, actionResult, quantity, page }) => ({ ...state })),

on(indexActions.SetProyectoPaginationAction, (state, { proyecto, popupId, searcText }) => {
  
  let item = {
    ...state.item
  };

item[popupId] = {
  ...state.item[popupId],
  proyecto: proyecto,
};
  return ({
    ...state,
    item
  });
  
 

}),

//localizaciones
on(indexActions.GetProvinciasByIdPaisAction, (state, { id, popupId }) => ({ ...state })),

on(indexActions.SetProvinciasByIdPaisAction, (state, { provincias, popupId }) => {
  
  let item = {
    ...state.item
  };
  item[popupId] = {
    ...state.item[popupId],
    provincias:provincias,
    ciudades: []
  };
  return ({
    ...state,
    item
  });
}),

on(indexActions.GetCiudadesByIdProvinciaAction, (state, { id, popupId }) => ({ ...state })),


on(indexActions.SetCiudadesByIdProvinciaAction, (state, { ciudades, popupId }) => {
  let item = {
    ...state.item
  };
  item[popupId] = {
    ...state.item[popupId],
    ciudades: ciudades
  };
  return ({
    ...state,
    item
  });
}),
on(indexActions.GetpreguntaAction, (state, { id, popupId }) => ({ ...state })),
/*on(indexActions.SetpreguntaAction, (state, { preguntas, popupId }) => {
  
  let item = {
    ...state.item
  };
  
  item[popupId] = {
    ...state.item[popupId],
    preguntas: preguntas
  };
  return ({
    ...state,
    item
  });
}),
*/
)



export function reducer(state: State | undefined, action: Action)
 {
    return addReducer(state, action);
}
export const GeneralFeatureKey = 'general';
