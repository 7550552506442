import { environment } from '../../environments/environment';
import { ActionReducer, MetaReducer, ActionReducerMap } from '@ngrx/store';
import { RouterStateUrl } from './router-state-url';
import * as fromRouter from '@ngrx/router-store';
import { storeFreeze } from 'ngrx-store-freeze';


export interface AppState {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const appReducer: ActionReducerMap<AppState | any> = {
  
  router: fromRouter.routerReducer
}

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    return reducer(state, action);
  }
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [/*logger,*/ storeFreeze] : [];


/*
import { ActionReducerMap } from '@ngrx/store'
import * as ui from '../general/ngrx/Loading/ui.reducer'

export interface AppState{
    ui:ui.State
}
export const appReducers:ActionReducerMap<AppState>={
    ui:ui.uiReducer,
    
}
*/