import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Botton_action, modules } from 'app/@components/confi/confi';
import * as indexActions from 'app/@components/general/ngrx/index/index.actions'
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';

@Component({
  selector: 'ngx-registroAsignacionProyectoDetailPopup',
  templateUrl: 'registroAsignacionProyectoDetailPopup.component.html'
})

export class RegistroAsignacionProyectoDetailPopupComponent implements OnInit, OnDestroy {
  Botton_action = Botton_action
  modules = modules
  popupId = uuid.v4();
  id: number;
  item: any;
  objectSub: Subscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RegistroAsignacionProyectoDetailPopupComponent>,
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.id = this.data.id
    if (this.id > 0) {
      this.store.dispatch(indexActions.GetByIdAction({ id: this.id, module: 'registroUsuarioProyecto', actionResult: undefined, popupId: this.popupId }));
    }
    this.objectSub = this.store.select((state) => state?.general?.item[this.popupId]?.object)
      .subscribe(object => {
        if (object) {

          this.item = object
        }
      });
  }
  
  ngOnDestroy() {
    this.objectSub.unsubscribe();
    this.store.dispatch(indexActions.ClearStorageAction({ popupId: this.popupId }));
  }
}
