


import { Injectable } from '@angular/core';
import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class CustomValidatorsService {

  constructor() { }

  startBeforeEnd(errorKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control) { return null; }

      const start = control.get("fT02FechaIni");
      const end = control.get("fT02FechaFin");
      if (!start.value || !end.value) {
        return null;
      }

      const startDate = new Date(start.value);
      const endDate = new Date(end.value);

      if (!this.isValidDate(startDate) || !this.isValidDate(endDate)) {
        return null;
      } else if (startDate > endDate) {
        const obj = {};
        obj[errorKey] = true;
        return obj;
      }
      return null;
    };
  }

  passwordsMatch(passwordKey: string, confirmPasswordKey: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control) { return null; }

      const password = control.get(passwordKey);
      const confirmPassword = control.get(confirmPasswordKey);
      if (!password.value || !confirmPassword.value) {
        return null;
      }

      if (password.value !== confirmPassword.value) {
        return { passwordMismatch: true };
      }
      return null;
    };
  }

  private isValidDate(date: any): boolean {
    return date instanceof Date && !isNaN(Number(date));
  }


  timeValidator(controlName: string, matchingControlName: string) {
    
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
        return;
      }
      let horaInicio = new Date(new Date().toDateString() + ' ' + control.value)
      let horaFin = new Date(new Date().toDateString() + ' ' + matchingControl.value)

      if (horaInicio > horaFin) {
        matchingControl.setErrors({ timeInvalid: true });
      }
      else {
        matchingControl.setErrors(null);
      }

    }
  }

}




















/*import { AbstractControl, FormControl, Validators } from '@angular/forms';


export class AppCustomDirective extends Validators {

  static fechaMayorValidator(control: AbstractControl)
{

  if (!control) { return null; }
  const start = control.get("fT02FechaIni");
  const end = control.get("fT02FechaFin");
  if (!start.value || !end.value) {
    return null;
  }
  const startDate = new Date(start.value);
  const endDate = new Date(end.value);
  if ( endDate<= startDate)
  {
    return { fechaMayorValidator: true };
  }
   // let fechaInicio;
   // fechaInicio=control.parent.controls
   // let fecha=fechaInicio.fT02FechaIni.value
   // const FECHAINICIO = new Date(fecha);
    //const FECHAFIN = new Date(control.value);
    //if(fecha)
    //{        //comprar si la fecha de inicio des menor o igula a fecha final
      //  if ( FECHAFIN<= FECHAFIN)
        //{
        //  return { fechaMayorValidator: true };
        //}

    //}

  return null;
}


}*/