<div class="altoTituloPopop">
  <span class="titulo">
    {{ data.title }} {{ modules.registroUsuarioProyecto.title }}</span
  >
  <span fxFlex></span>
  <button
    type="button"
    mat-icon-button
    class="mt--10"
    (click)="dialogRef.close()"
  >
    <mat-icon> close </mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <div class="form-group row">
    <label class="label col-sm-3 col-form-label">Duración:</label>
    <div class="col-sm-9">
      {{ item?.registroUsuarioProyecto[0]?.horaDuracion | formatTimer : 5 }}
    </div>
  </div>

  <div class="form-group row">
    <label class="label col-sm-3 col-form-label">Fecha:</label>
    <div class="col-sm-9">{{ item?.fT08Inicio | date : "dd/MM/yyyy" }}</div>
  </div>

  <!-- Campo Proyecto -->
  <div class="form-group row">
    <label class="label col-sm-3 col-form-label">Proyecto:</label>
    <div class="col-sm-9">{{ item?.fT02Nombre }}</div>
  </div>
</mat-dialog-content>

<!--Sección Cancelar-->
<mat-dialog-actions align="end">
  <button
    class="mr-1"
    type="button"
    size="small"
    matRipple
    nbButton
    status="disabled"
    (click)="dialogRef.close(false)"
  >
    {{ Botton_action.Cancelar.nombre }}
  </button>
</mat-dialog-actions>
