
import { NgModule, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpRequest } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RoleProvider } from './role.provider';
import {
  NbAuthJWTInterceptor,
  NbAuthModule,
  NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
  NbTokenLocalStorage,
} from '@nebular/auth';
import { AuthInterceptor } from './auth.interceptor';
import { AuthPipe } from './auth.pipe';
import {  NbRoleProvider, NbSecurityModule } from '@nebular/security';

import {
  NgxLoginComponent,
  NgxAuthComponent,
  NgxLogoutComponent,
  NgxRequestPasswordComponent
} from './components';

import {
  NbAlertModule,
  NbCardModule,
  NbIconModule,
  NbLayoutModule,
  NbCheckboxModule,
  NbInputModule,
  NbButtonModule,
  NbSpinnerModule,
} from '@nebular/theme';
import { AuthRoutingModule } from './auth-routing.module';
import { ComponentsModule } from '../@components/components.module';
import { authSettings } from './access.settings';
import { AuthGuard } from './auth.guard';
import {MatDialogModule} from '@angular/material/dialog';
import{NgxResetPasswordComponent} from './components/reset-password/reset-password.component'
import { EmpresaLinkPopupComponent } from './components/login/empresaLink/empresaLink-popup.component';
import {HttpAjaxInterceptor} from './http.ajax.interceptor'
import { StoreModule } from '@ngrx/store';
import * as authReducer from './ngrx/auth.reducer'
import { EffectsModule } from '@ngrx/effects';
import {AuthEffects} from './ngrx/auth.effects'
import {TokenInterceptor} from './token.interceptor'
const PIPES = [AuthPipe];
const GUARDS = [AuthGuard];
const COMPONENTS = [
  NgxLoginComponent,
  NgxAuthComponent,
  NgxLogoutComponent,
  NgxRequestPasswordComponent,
  NgxResetPasswordComponent,
  EmpresaLinkPopupComponent
];
const NB_MODULES = [
  NbIconModule,
  NbLayoutModule,
  NbCardModule,
  NbAlertModule,
  NbCheckboxModule,
  NbInputModule,
  NbButtonModule,
];

export function filterInterceptorRequest(req: HttpRequest<any>): boolean {
  return ['/auth/login','/auth/request-pass', '/auth/refresh-token']
    .some(url => req.url.includes(url));
}

@NgModule({
  declarations: [...PIPES, ...COMPONENTS],
  imports: [
    AuthRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    ComponentsModule,
    NbSpinnerModule,
    MatDialogModule,
    ...NB_MODULES,
    NbAuthModule.forRoot(),
    StoreModule.forFeature(authReducer.authFeatureKey, authReducer.reducer),
    EffectsModule.forFeature([AuthEffects]),
  ],
  exports: [...PIPES],
  providers: [
    NbSecurityModule.forRoot({
      accessControl: authSettings,
    }).providers,
    {
      provide: NbRoleProvider, useClass: RoleProvider,
    },
    {
      provide: NbTokenLocalStorage, useClass: NbTokenLocalStorage,
    },
  ],
  entryComponents: [EmpresaLinkPopupComponent]
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
       { provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER, useValue: filterInterceptorRequest },
        { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpAjaxInterceptor, multi: true },
        ...GUARDS],
    };
  }
}
