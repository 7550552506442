import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as generalActions from 'app/@components/general/ngrx/general.actions';
import { Empresa } from 'app/@components/models/Empresa';
import * as uuid from 'uuid';
import { Botton_action, modules } from 'app/@components/confi/confi';
import * as indexActions from 'app/@components/general/ngrx/index/index.actions';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudDeletePopupComponent } from '../crud-delete-popup/crud-delete-popup.component';
@Component({
  selector: 'ngx-crud-select-empresa',
  templateUrl: './crud-select-empresa.component.html',
  styleUrls: ['./crud-select-empresa.component.scss']
})
export class CrudSelectEmpresaComponent implements OnInit, OnDestroy {
  empresas:Empresa[]=[]
  dataFormSub: Subscription = new Subscription();
  popupId = uuid.v4();
  Botton_action=Botton_action
  modules=modules
  item:Empresa
  constructor(  private store: Store<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CrudDeletePopupComponent>,
) { }
  ngOnInit(): void {
        //obtienes los datos para realizar la busqueda
        this.store.dispatch(generalActions.GetDataFormAction({ id: undefined, module: this.modules.empresa.name, popupId: this.popupId, actionResult: 'GetData' }));
        this.dataFormSub = this.store.select((state) => state?.general?.item[this.popupId]?.dataForm)
          .subscribe(dataForm => {
            if (dataForm) {
              this.empresas = dataForm?.empresas;
            }
          })
  }
  ngOnDestroy(): void {
    this.dataFormSub.unsubscribe();
    this.store.dispatch(generalActions.ClearStorageAction());
    
  }
  search(item:any) 
  {
    this.item=item;
    let data={fS07Id:item.fS07Id}
    this.store.dispatch(indexActions.GetParametersReportschAction({ parameters: data}));
    //this.store.dispatch(indexActions.GetItemsAction());

  }
  close(): void {
    this.dialogRef.close();
  }
  submit() 
  {
    this.store.dispatch(indexActions.SetItemEmpresaAction({ itemEmpresaPupop: this.item }));
    this.close();

  }
}
