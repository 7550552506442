import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
@Component({
  selector: 'ngx-crud-without-search',
  templateUrl: './crud-without-search.component.html',
  styleUrls: ['./crud-without-search.component.scss']
})
export class CrudWithoutSearchComponent implements OnInit, OnDestroy {
  loading = false;
  loadingSub: Subscription = new Subscription();
  constructor(    private store: Store<any>,
    private cd: ChangeDetectorRef,) { }
 
  ngOnInit(): void {
  
  }
  ngOnDestroy(): void {
    this.loadingSub.unsubscribe()
  }
}
