<div class="altoTituloPopop">
  <span class="titulo"> {{data.title }} {{modules.clienteProveedor.title}} -- <span> {{empresa?.fS07NombreComercial}}</span></span>
  <span fxFlex></span>
  <button type="button" mat-icon-button class="mt--10" (click)="dialogRef.close()">
    <mat-icon>
      close
    </mat-icon>
  </button>

</div>
<mat-dialog-content class="mat-typography dialog-content-400">
  <form novalidate [formGroup]="itemForm" autocomplete="off" (ngSubmit)="save()">
    <div class="row">
      <div class="col-md-12 col-lg-12 col-xxxl-12">
        <div class="form-group">
          <mat-form-field class="input-example">
            <mat-label class="label">Razon Social</mat-label>
            <input matInput formControlName="fP01RazonSocial"  required>
          </mat-form-field>
          <ngx-validation-message-crud label="Razon Social"
            [showRequired]="fP01RazonSocial?.errors?.required && fP01RazonSocial.touched">
          </ngx-validation-message-crud>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 col-xxxl-6">
        <div class="form-group">
          <mat-form-field class="input-example">
            <mat-label class="label">Dirección</mat-label>
            <input matInput formControlName="fP01Direccion"  required>
          </mat-form-field>
          <ngx-validation-message-crud label="Dirección"
          [showRequired]="fP01Direccion?.errors?.required && fP01Direccion.touched">
        </ngx-validation-message-crud>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 col-xxxl-6">
        <div class="form-group">
          <mat-form-field class="input-example">
            <mat-label class="label">Telefono</mat-label>
            <input matInput formControlName="fP01Telefono"  required>
          </mat-form-field>
          <ngx-validation-message-crud label="Telefono"
          [showRequired]="fP01Telefono?.errors?.required && fP01Telefono.touched">
        </ngx-validation-message-crud>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 col-xxxl-6">
        <div class="form-group">
          <mat-form-field class="input-example">
            <mat-label class="label">Correo Electronico</mat-label>
            <input matInput formControlName="fP01CorreoElectronico" >
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 col-xxxl-6">
        <div class="form-group">
          <mat-form-field class="input-example">
            <mat-label class="label">Ruc</mat-label>
            <input matInput formControlName="fP01Ruc"  required>
          </mat-form-field>
          <ngx-validation-message-crud label="Ruc"
          [showRequired]="fP01Ruc?.errors?.required && fP01Ruc.touched">
        </ngx-validation-message-crud>
        </div>
      </div>
      <div class="col-md-12 col-lg-3 col-xxxl-3">
        <div class="form-group top">
          <mat-form-field class="input-example">
          <mat-select   formControlName="fP01Tipo"  placeholder="Seleccione">
            <mat-option  value="N">Natural</mat-option>
            <mat-option   value="J">Judirico</mat-option>
            <mat-option  value="R">RISE</mat-option>
            <mat-option   value="E">Especial</mat-option>
          </mat-select>
        </mat-form-field>
        </div>
      </div>
  
      <div class="col-md-12 col-lg-3 col-xxxl-3">
        <div class="form-group top">
          <section class="example-section">
            <mat-checkbox class="example-margin label" formControlName="fP01Cliente">
            Cliente
            </mat-checkbox>
          </section>
        </div>
      </div>
      <div class="col-md-12 col-lg-3 col-xxxl-3">
        <div class="form-group top">
          <section class="example-section">
            <mat-checkbox class="example-margin label" formControlName="fP01Proveedor">
            Proveedor
            </mat-checkbox>
          </section>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 col-xxxl-6">
        <div class="form-group">
          <div class="input-example toggleTop">
            <label class="label">Estado</label>
            <mat-slide-toggle formControlName="fP01Activo"></mat-slide-toggle>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-3 col-xxxl-3">
        <div class="form-group top">
          <section class="example-section">
            <mat-checkbox class="example-margin label" formControlName="fP01ProveedorEvaluado">
            Evaluar
            </mat-checkbox>
          </section>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="mr-1" type="button" size="small" matRipple nbButton status="disabled"
    (click)="dialogRef.close(false)">{{Botton_action.Cancelar.nombre}}</button>
  <button nbButton matRipple size="small" (click)="save()" status="primary">{{Botton_action.Guardar.nombre}}</button>

</mat-dialog-actions>