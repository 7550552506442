
import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanLoad, UrlSegment, UrlTree, CanActivateChild } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { AuthTokenService } from './authToken.service';
import { Store } from '@ngrx/store';
import * as generalActions from 'app/@components/general/ngrx/general.actions';
@Injectable()
export class AuthGuard implements CanActivate, CanLoad, CanActivateChild, OnDestroy {
  menu: any[] = []
  menusSub: Subscription = new Subscription();
  constructor(
    private authService: AuthTokenService,
    private store: Store<any>,
    private router: Router,
    
  ) {
    this.menusSub = this.store.select((state) => state?.auth?.lecturaMenus)
      .subscribe(data => {
        if (data) {
          this.menu = data
        }
      });
  }
  ngOnDestroy(): void {
    this.menusSub.unsubscribe();
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
   return null;
    /* if (!this.authService.ValidateAuthentication()) {
      this.authService.logout();
      return false
    }
    else {
      //valida si tiene permiso para la ruta
      if (this.menu.length > 0) {
        let data = this.menu.find(i => i.link == childRoute.routeConfig.data["path"]);
        if (data) {
         this.store.dispatch(generalActions.SetPath({ path: data.link }));
          return true;
        }
        else {
          this.router.navigate(['/']);
          return false;
        }
      }
      else {
        this.menusSub = this.store.select((state) => state?.auth?.lecturaMenus)
          .subscribe(res => {
            if (res) {
              
              this.menu = res
              let data = this.menu.find(i => i.link == childRoute.routeConfig.data["path"]);
              if (data) {
                this.store.dispatch(generalActions.SetPath({ path: data.link }));
                return true;
              }
              else {
                this.router.navigate(['/']);
                return false;
              }
            }
          });
        return true;
      }

    }*/
  }
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.checkLogin()) 
    {
     // this.authService.ValidateLocalStore();
      /*this.menusSub = this.store.select((state) => state?.auth?.lecturaMenus)
        .subscribe(res => {
          if (res) {
            this.menu = res
          }
        }
        )*/
    }
    return this.checkLogin()
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.checkLogin()) {
      //if (this.menu.length > 0)
      // {
        let data = this.menu.find(i => i.link == route.routeConfig.data["path"]);
        if (data) {
          //this.store.dispatch(generalActions.SetPath({ path: data.link }));
          return true;
        }
        else {
          this.router.navigate(['/']);
          return false;
        }
     /* }
      else {
       this.menusSub = this.store.select((state) => state?.auth?.lecturaMenus)
          .subscribe(res => {
            if (res) {
              
              this.menu = res
              let data = this.menu.find(i => i.link == route.routeConfig.data["path"]);
              if (data) {
              // this.store.dispatch(generalActions.SetPath({ path: data.link }));
                return true;
              }
              else {
                this.router.navigate(['/']);
                return false;
              }
            }
          });
        return true;
      }*/
    }
    return this.checkLogin()
  }

  checkLogin() {
    if (this.authService.ValidateAuthentication()) return true;
    this.router.navigate(['/']);
    return false;
  }
}
