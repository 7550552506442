<nb-card>
  <nb-card-header>Selecciona la aplicación</nb-card-header>
  <nb-card-body>
    <div class="row " *ngFor='let item of detalle'>
      <div class="col-md-6 col-lg-6 col-xxxl-6" style="margin-top: 5px;">
       <p>
         {{item.aP01Nombre}}
       </p>
      </div>
      <div class="col-md-6 col-lg-6 col-xxxl-6" style="margin-top: 5px;">
        
        <button  nbButton matRipple (click)="submit(item.aP01Url)" status="success">
         Ingresar
        </button>
      </div>
      </div>
  
  </nb-card-body>

</nb-card>


