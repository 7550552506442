import {Injectable} from '@angular/core';
import {HttpHandler, HttpRequest, HttpInterceptor} from '@angular/common/http';
import {finalize} from 'rxjs/internal/operators';
import { Store } from '@ngrx/store';
import * as generalActions from '../@components/general/ngrx/general.actions';
@Injectable()
export class HttpAjaxInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<any>
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.store.dispatch(generalActions.EnableLoadAction());
    return next.handle(req)
    .pipe(
      finalize(() => {
        this.store.dispatch(generalActions.DisableLoadAction());
      })
    );
  }
}
