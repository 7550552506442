import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NB_AUTH_OPTIONS } from "@nebular/auth";
import { getDeepFromObject } from "../../helpers";
import { NbThemeService } from "@nebular/theme";
import { EMAIL_PATTERN } from "../constants";
import { InitUserService } from "../../../@theme/services/init-user.service";
import { AuthService } from "../../auth.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EmpresaLinkPopupComponent } from "./empresaLink/empresaLink-popup.component";
import { ClienteAppDetalle } from "app/@components/models/ClienteAppDetalle";
import { modal_position, modal_size } from "app/@components/confi/confi";
@Component({
  selector: "ngx-login",
  templateUrl: "./login.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NgxLoginComponent implements OnInit {
  minLength: number = this.getConfigValue(
    "forms.validation.password.minLength"
  );
  maxLength: number = this.getConfigValue(
    "forms.validation.password.maxLength"
  );
  redirectDelay: number = this.getConfigValue("forms.login.redirectDelay");
  showMessages: any = this.getConfigValue("forms.login.showMessages");
  strategy: string = this.getConfigValue("forms.login.strategy");
  //socialLinks: NbAuthSocialLink[] = this.getConfigValue('forms.login.socialLinks');
  // rememberMe = this.getConfigValue('forms.login.rememberMe');
  isEmailRequired: boolean = this.getConfigValue(
    "forms.validation.email.required"
  );
  isPasswordRequired: boolean = this.getConfigValue(
    "forms.validation.password.required"
  );

  //errors: string[] = [];
  errors = false;
  success = false;
  submitted: boolean = false;
  loginForm: FormGroup;
  // alive: boolean = true;
  loading = false;
  get email() {
    return this.loginForm.get("email");
  }
  get password() {
    return this.loginForm.get("password");
  }
  sinAplicacion = false;
  public open = true;
  constructor(
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    protected cd: ChangeDetectorRef,
    protected themeService: NbThemeService,
    private fb: FormBuilder,
    protected router: Router,
    protected initUserService: InitUserService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  //variable para controlar la visibilidad de contraseña

  showPassword: boolean = false;
  //funcion para ver mostrar la contraseña
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  ngOnInit(): void {
    const emailValidators = [Validators.pattern(EMAIL_PATTERN)];
    this.isEmailRequired && emailValidators.push(Validators.required);
    const passwordValidators = [];
    this.isPasswordRequired && passwordValidators.push(Validators.required);

    this.loginForm = this.fb.group({
      email: this.fb.control("", [...emailValidators]),
      password: this.fb.control("", [...passwordValidators]),
    });
  }

  login(): void {
    this.loading = true;
    this.open = true;
    this.submitted = true;
    this.authService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        (data) => {
          this.submitted = false;
          this.loading = false;
          if (data.success) {
            this.sinAplicacion = false;
            this.errors = false;
            this.success = true;
            this.loginForm.reset();
            if (data.clienteAppDetalle.length > 1) {
              this.openPopUp(data.clienteAppDetalle);
            } else if (data.clienteAppDetalle.length == 1) {
              window.location.href = data.clienteAppDetalle[0].aP01Url;
            } else {
              this.sinAplicacion = true;
            }
          } else {
            this.errors = true;
            this.success = false;
            //this.errors = result.getErrors();
          }
          //return this.router.navigateByUrl('/pages');
          this.cd.detectChanges();
          /* const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }*/
          this.cd.detectChanges();
        },
        (err) => {
          this.errors = true;
          this.success = false;
          this.submitted = false;
          this.loading = false;
          this.cd.detectChanges();
        }
      );
  }

  getConfigValue(key: string): any {
    return getDeepFromObject(this.options, key, null);
  }

  openPopUp(clienteAppDetalle?: ClienteAppDetalle[]) {
    let dialogRef: MatDialogRef<any> = this.dialog.open(
      EmpresaLinkPopupComponent,
      {
        width: modal_size.MD,
        // position: { top: modal_position.top },
        disableClose: true,
        data: { payload: clienteAppDetalle },
      }
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        // If user press cancel
        return;
      }
      window.location.href = res;
    });
  }
}
