import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import {
  Botton_action,
  modal_position,
  modal_size,
  pageSizeOptions,
  titulos,
  modules,
} from "app/@components/confi/confi";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, Sort } from "@angular/material/sort";
import * as generalActions from "app/@components/general/ngrx/general.actions";
import * as indexActions from "app/@components/general/ngrx/index/index.actions";
import { PageEvent } from "@angular/material/paginator";
import { CrudDeletePopupComponent } from "app/@components/general/componentes-crud/crud-delete-popup/crud-delete-popup.component";
import { RegistroAsignacionProyectoPopupAddUpdComponent } from "./popupAddUpd/registroAsignacionProyectoPopupAddUpd.component";
import { RegistroAsignacionProyectoDetailPopupComponent } from "./detailPopup/registroAsignacionProyectoDetailPopup.component";
import { RegistroUsuarioProyecto } from "app/@components/models/RegistroUsuarioProyecto";
import { Empresa } from "app/@components/models/Empresa";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { UtilService } from "app/@components/services/util.service";
import { RespuestaService } from "app/@components/respuesta.service";
import { ShowToastService } from "app/@components/showToast.service";
import { HttpService } from "app/@components/general/services/http.service";
import * as uuid from "uuid";
@Component({
  selector: "ngx-registroAsignacionProyecto",
  templateUrl: "./registroAsignacionProyecto.component.html",
  styleUrls: ["./registroAsignacionProyecto.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class RegistroAsignacionProyectoComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  items: RegistroUsuarioProyecto[];
  itemsSub: Subscription = new Subscription();
  displayedColumns: string[] = [];
  displayedColumns1: string[] = [];
  dataSource = new MatTableDataSource<any>([]);
  length = undefined;
  pageSize = undefined;
  pageIndex = undefined;
  pageSizeOptions = pageSizeOptions;
  pageEvent: PageEvent;
  indexSubscription: Subscription = new Subscription();
  actionCreateSubscription: Subscription = new Subscription();
  actionLecturaSubscription: Subscription = new Subscription();
  Botton_action = Botton_action;
  lectura: any = undefined;
  modules = modules;
  empresa: Empresa;
  minDate: Date;
  datosFiltrados: any[] = []; // Aquí guardarás los datos filtrados
  diaFecha: any[] = [];
  popupId = uuid.v4();
  public fS07Id: string;
  public aS02Id: string;
  constructor(
    private store: Store<any>,
    public dialog: MatDialog,
    public utilService: UtilService,
    private respuestaService: RespuestaService,
    private showToastService: ShowToastService,
    private http: HttpService
  ) {}

  ngOnInit() {
    this.lectura = this.utilService.validateReading(
      this.modules.registroUsuarioProyecto.path
    );

    this.itemsSub = this.store
      .select((state) => state?.general?.index?.items)
      .subscribe((items) => {
        if (items && items.length > 0) {
          this.items = items;
          const primerItem = items[0];
          if (primerItem && primerItem.fS07Id && primerItem.aS02Id) {
            this.fS07Id = primerItem.fS07Id;
            this.aS02Id = primerItem.aS02Id;
            const fechaInicio = new Date();
            fechaInicio.setDate(fechaInicio.getDate() - 15);
            const fechaFin = new Date();
            this.http
              .datosDiasFecha(
                this.fS07Id,
                this.aS02Id,
                this.formatearFecha(fechaInicio),
                this.formatearFecha(fechaFin)
              )
              .subscribe((datos: any) => {
                this.diaFecha = Object.values(datos);

                this.dataSource.data = this.diaFecha[3].elementos;
              });
          } else {
          }
        } else {
          this.dataSource.data = [];
        }
      });

    this.indexSubscription = this.store
      .select((state) => state?.general?.index)
      .subscribe((index) => {
        if (index != null && index != undefined && index?.count) {
          this.length = index.count;
          this.pageSize = index.quantity;
          this.pageIndex = index.page;
        }
      });

    this.actionCreateSubscription = this.store
      .select((state) => state?.general?.modulo?.actionCreate)
      .subscribe((actionCreate) => {
        if (actionCreate) {
          if (this.empresa) {
            this.insertEditDataPopup(0, "post");
          } else {
            let respuesta = this.respuestaService.itemEmpresa();
            this.showToastService.showToast(
              "info",
              respuesta.message,
              respuesta.detalle
            );
          }
        }
      });

    this.displayedColumns = ["Dia", "fT08Inicio", "Usuario", "Acciones"];
    this.displayedColumns1 = ["horaDuracion", "Acciones"];

    this.store.dispatch(
      generalActions.SetTititleCrud({
        title: modules.registroAsignacionProyecto.title,
        name: modules.registroUsuarioProyecto.name,
      })
    );

    this.itemsSub = this.store
      .select((state) => state?.general?.index?.itemEmpresa)
      .subscribe((itemEmpresa) => {
        if (itemEmpresa != null) {
          this.empresa = itemEmpresa;
        }
      });
  }

  buscar(fechaInicio: Date, fechaFin: Date) {
    this.itemsSub = this.store
      .select((state) => state?.general?.index?.items)
      .subscribe((items) => {
        if (items && items.length > 0) {
          this.items = items;
          const primerItem = items[0];
          if (primerItem && primerItem.fS07Id && primerItem.aS02Id) {
            const fS07Id = primerItem.fS07Id;
            const aS02Id = primerItem.aS02Id;
            this.diaFecha = [];
            this.http
              .datosDiasFecha(
                fS07Id,
                aS02Id,
                this.formatearFecha(fechaInicio),
                this.formatearFecha(fechaFin)
              )
              .subscribe((datos: any) => {
                this.diaFecha = Object.values(datos);

                this.dataSource.data = this.diaFecha[3].elementos;
              });
          } else {
          }
        } else {
          this.dataSource.data = [];
        }
      });
  }

  ngOnDestroy(): void {
    this.itemsSub.unsubscribe();
    this.indexSubscription.unsubscribe();
    this.actionCreateSubscription.unsubscribe();
    this.actionLecturaSubscription.unsubscribe();
    this.store.dispatch(generalActions.ClearStorageAction());
  }

  insertEditDataPopup(id: any, accion: any) {
    let title =
      id == 0 ? Botton_action.Crear.nombre : Botton_action.Editar.nombre;
    this.dialog.open(RegistroAsignacionProyectoPopupAddUpdComponent, {
      width: modal_size.LG,
      position: { top: modal_position.top },
      disableClose: true,
      data: {
        title: "title",
        id: id,
        empresa: this.empresa,
        aS02Id: this.aS02Id,
        fS07Id: this.fS07Id,
        accion: accion,
      },
    });
  }

  sortData(sort: Sort) {
    if (sort.active && sort.direction) {
      this.store.dispatch(
        indexActions.ChangeOrderByAndOrderTypeAction({
          orderBy: sort.active,
          orderType: sort.direction,
        })
      );
    } else {
      this.store.dispatch(
        indexActions.ChangeOrderByAndOrderTypeAction({
          orderBy: undefined,
          orderType: undefined,
        })
      );
    }
  }

  changePagination($event) {
    this.pageEvent = $event;
    this.store.dispatch(
      indexActions.NumberPageQuantityAction({
        page: this.pageEvent.pageIndex,
        quantity: this.pageEvent.pageSize,
      })
    );
  }

  detailPopUp(id: any) {
    this.dialog.open(RegistroAsignacionProyectoDetailPopupComponent, {
      width: modal_size.MD,
      position: { top: modal_position.top },
      disableClose: true,
      data: { title: titulos.detallePopop, id: id },
    });
  }

  deletePopUp(data: any) {
    let items: any[] = [];
    let fechaSinHora: string = "";

    if (data?.fT08Inicio) {
      const fechaConHora: Date = new Date(data.fT08Inicio);
      fechaConHora.setHours(0, 0, 0, 0);
      fechaSinHora = fechaConHora.toISOString().split("T")[0];
    }

    let item = {
      id: data?.fT08Id,
      nombre:
        "Proyecto: " +
        data?.fT02Nombre +
        " --- Fecha: " +
        fechaSinHora +
        " --- Duración: " +
        data?.horaDuracion,
    };

    items.push(item);

    this.dialog.open(CrudDeletePopupComponent, {
      width: modal_size.MD,
      position: { top: modal_position.top },
      disableClose: true,
      data: {
        title:
          titulos.eliminarPopop + " " + modules.registroUsuarioProyecto.title,
        module: modules.registroUsuarioProyecto.name,
        aS02Id: this.aS02Id,
        fS07Id: this.fS07Id,
        data: data,
      },
    });
  }

  formatearFecha(date) {
    date.setDate(date.getDate() + 1);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
}
