import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Botton_action } from 'app/@components/confi/confi';
import { Subscription } from 'rxjs';
@Component({
  selector: 'ngx-crud-title',
  templateUrl: './crud-title.component.html',
  styleUrls: ['./crud-title.component.scss']
})
export class CrudTitleComponent implements OnInit, OnDestroy {
  Botton_action=Botton_action
  titleCrudSubs: Subscription = new Subscription();
  titleCrud=''
  constructor(    
    private store: Store<any>,
    ) {
   }
  ngOnInit(): void {
    this.titleCrudSubs = this.store.select((state) => state?.general?.modulo?.title)
      .subscribe(title => {
        if(title) {
          this.titleCrud = title;
        }
      });
  }
  ngOnDestroy(){
    this.titleCrudSubs.unsubscribe();
  }
  
}
