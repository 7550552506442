
import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Botton_action } from 'app/@components/confi/confi';



@Component({
  selector: 'error-pupup',
  templateUrl: 'error-pupup.component.html',
  styleUrls: ['error-pupup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ErrorPopupComponent implements OnInit, OnDestroy {
  Botton_action=Botton_action
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ErrorPopupComponent>) 
    {}
  ngOnDestroy(): void
  {

  }
    ngOnInit()
    {

   }
  
   close() 
   {

    this.dialogRef.close();
  }
  
}
