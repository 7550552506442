
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Botton_action, modules } from 'app/@components/confi/confi';
import * as indexActions from 'app/@components/general/ngrx/index/index.actions'
import { ClienteProveedor } from 'app/@components/models/ClienteProveedor';
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';
@Component({
  selector: 'ngx-clienteProveedorDetailPopup',
  templateUrl: 'clienteProveedorDetailPopup.component.html',
  styleUrls: ['clienteProveedorDetailPopup.component.scss'],
  

})
export class ClienteProveedorDetailPopupComponent implements OnInit, OnDestroy {
  Botton_action = Botton_action
  popupId = uuid.v4();
  id: number;
  item: ClienteProveedor;
  objectSub: Subscription = new Subscription();
  url;
  modules=modules
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ClienteProveedorDetailPopupComponent>,
    private store: Store<any>
  ) { }

  ngOnInit() {
    this.id = this.data.id
    if (this.id > 0) {
      this.store.dispatch(indexActions.GetByIdAction({ id: this.id, module: 'ClienteProveedor', actionResult: undefined, popupId: this.popupId }));
    }
    this.objectSub = this.store.select((state) => state?.general?.item[this.popupId]?.object)
      .subscribe(object => {
        if (object) {
          this.item = object
        }
      });
  }

  ngOnDestroy() {
    this.objectSub.unsubscribe();
    //this.store.dispatch(indexActions.ClearStorageAction({ popupId: this.popupId }));
  }


}
